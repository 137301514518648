
import { AnchorButton, Button,  ButtonGroup,  H3, HTMLTable, Icon, InputGroup,  Slider, Spinner, Tab, Tabs } from '@blueprintjs/core';
import * as React from "react";
import { useEffect, useState } from "react";
import { DateRange, DateRangeInput } from '@blueprintjs/datetime';
import { format, Locale, parse } from "date-fns";
import '../assets/css/datetime.css'
import { firebase, functions } from '../global/firebase';
import { httpsCallable } from 'firebase/functions';
import { addDoc, collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import csvDownload from 'json-to-csv-export'
import { useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import ProgressBar from '@ramonak/react-progress-bar';
import { ScaleLoader } from 'react-spinners';
import Summaryreportbygroup from '../components/summaryreportbygroup';

const locales: { [localeCode: string]: Locale } = require("date-fns/locale");


const Reports=()=>{

  const auth:any = useAuthUser();
  const user =  auth()
  console.log(user);
  
    return(
        <div className="main_div_report" >
               <Tabs
                animate={true}
                key={"vertical"}
                vertical={false}>

                <Tab id="rx1" title="Check-Ins Report" panel={ 
                <div>
                    <H3>Check-Ins Report</H3>
                    <div>
                        <ReportForm functionName="generateCheckInsReport" Name='checkIn'/>
                    </div>
                </div>
                }/>
                <Tab id="rx2" title="Vehicle Picks Report" panel={ 
                <div>
                    <H3>Vehicle Picks Report</H3>
                    <div>
                    <ReportForm functionName="generateVehiclePicksReport"  Name='vehiclePick'/>
                    </div>
                </div>
                }/>
                <Tab id="rx3" title="Reports Delivered to Mail" panel={ 
                <div>
                    <H3>Reports Delivered to Mail</H3>
                    <div>
                    <ReportForm functionName="reportToMail" Name='delivertomain'/>
                    </div>
                </div>
                }/>
                <Tab id="rx4" title="Void Check-Ins Report" panel={ 
                <div>
                    <H3>Void Check-Ins Report</H3>
                    <div>
                    <ReportForm functionName="voidCheckins" Name='voidCheckins'/>
                    </div>
                </div>
                }/>


       {(user!==undefined  && user!==null) && (user.accountData.permissions!==undefined && user.accountData.permissions.archivecheckinreport!==undefined &&  user.accountData.permissions.archivecheckinreport===true) || ( user.accountData.permissions.role!==undefined &&  user.accountData.permissions.role=='admin' ) ? 
       <Tab id="rx5" title="Check-Ins Report older than 12 months"  panel={ 
                <div>
                    <H3>Check-Ins Report (CST Timezone)</H3>
                    <div>
                    <ReportForm functionName="generateArchivedCheckInsReport" Name='archivedreport'/>
                    </div>
                </div>
                }/>:''}


{(user!==undefined  && user!==null) && (user.accountData.permissions!==undefined && user.accountData.permissions.checkinsummaryreport!==undefined &&  user.accountData.permissions.checkinsummaryreport===true) || ( user.accountData.permissions.role!==undefined &&  user.accountData.permissions.role=='admin' ) ? <Tab id="rx56" title="Check-Ins Summary Report"  panel={ 
                <div>
                    <H3>Check-Ins Summary Report</H3>
                    <div>

                <Tabs
                animate={true}
                key={"vertical"}
                vertical={false}>

                       {(user!==undefined  && user!==null) && (user.accountData.permissions!==undefined) && (( user.accountData.permissions.groups!==undefined &&   user.accountData.permissions.groups.length>0) || (user.accountData.permissions.role!==undefined && user.accountData.permissions.role=='admin'))?  
                        <Tab id="rx6" title="Summary Report Business Group"  panel={                        
                          <div>
                          <Summaryreportbygroup />
                          </div>
                     
                          }/> :''}

                        <Tab id="rx7" title="Summary report by business"  panel={ 
                      
                        <div>
                        <ReportForm functionName="getCheckInscountReports" Name='CheckInsReportcount'/>                   
                        </div>
                        }/>
                </Tabs>

                 
                    </div>
                </div>
                }/>:''}


                </Tabs>
        </div>
    )

}


export default Reports


export const DATE_FNS_FORMATS: any[] = [
    getDateFnsFormatter("MM/dd/yyyy"),
    getDateFnsFormatter("yyyy-MM-dd"),
    getDateFnsFormatter("yyyy-MM-dd HH:mm:ss"),
    getDateFnsFormatter("LLL do, yyyy 'at' K:mm a"),
];

function getDateFnsFormatter(formatStr: string): any {
    return {
        formatDate: (date:any, localeCode:any) => format(date, formatStr, maybeGetLocaleOptions(localeCode)),
        parseDate: (str:any, localeCode:any) => parse(str, formatStr, new Date(), maybeGetLocaleOptions(localeCode)),
        placeholder: `${formatStr}`,
    };
}

function maybeGetLocaleOptions(localeCode: string): { locale: Locale } | undefined {
    if (locales[localeCode] !== undefined) {
        return { locale: locales[localeCode] };
    }
    return undefined;
}


let unsubscribe = ()=>{};
export  const ReportForm=(data:any)=>{
    const [businesses,setbusiness]=useState([] as any);
    const [sites,setsites]=useState([] as any);
    const[ range,setrange]=useState([null,null] as DateRange)
    const [formate,setformate]=useState(DATE_FNS_FORMATS[0])
    const [businessId,setbusinessId]=useState('');
    const [businessPath,setbusinessPath]=useState('');
    const [sitePath,setsitePath]=useState('');
    const [loading,setloading]=useState(false)
    const [tableloading,settableloading]=useState(false)
    const [siteId,setsiteId]=useState('');
    const [csv,setcsv]=useState('');
    const [Email,setEmail]=useState('');
    const [Period,setPeriod]=useState('weekly')
    const [ReportOf,setReportOf]=useState('vehicle-picks')
    const [bool,setbool]=useState(false)
    const [progressx,setprogress]=useState(0)
    const [datesarray,setdatesarray]=useState([] as any)
    const [datesarrayinit,setdatesarrayinit]=useState([] as any)
    const [selectedmonth,setselectedmonth] = useState('');

    const final:any = useSelector(state=>state);
      useEffect(()=>{

         if(formate===undefined){ setformate(DATE_FNS_FORMATS[0]) }         
            if(businesses[0]===undefined){                    
            setbool(!bool)
            const lengthplus=(final.BusinessesReducer.Businesses.length+final.BusinessesReducer.Archived.length)

     
            if(final.BusinessesReducer.Businesses[0]!==undefined && final.BusinessesReducer.Businesses!==null
              ){  

                
                try {
      if(data.functionName==='getCheckInscountReports'){
        const b:any =[]

        final.BusinessesReducer.Businesses.map((val:any)=>{
        if(val.sites!==undefined){
          
        const si =  val.sites.filter((r:any)=>( r.summeryreport!==undefined && r.summeryreport!==false))
 
          if(si.length>0){
            b.sites= si
            b.push(val)
          }


          
        }
        })
        // console.log(b,'kkkkkkkkkkkkkkkk');
        
        setbusiness(b)
        setsites(b[0].sites) 
        
      }else{

        setbusiness(final.BusinessesReducer.Businesses)
        setsites(final.BusinessesReducer.Businesses[0].sites)   
      }

                
                
            } catch (error) {
                   console.log(error);
                    
            }
            }           
        }          


// select input options 
            const currentDate = new Date();
            const selectElement:any = document.getElementById('last-12-months')!;
            if(selectElement!==null){
            selectElement.innerHTML ='<option value="">Last 12 Months</option>';
            // Generate last 12 months
            for (let i = 0; i < 12; i++) {
                const option = document.createElement('option');
                const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
                const month = date.toLocaleString('default', { month: 'long' });
                const year = date.getFullYear();
                const value =`${year}-${String(date.getMonth() + 1)}-01`;
                option.value = value;
                option.textContent = `${month} (${year})`;
                selectElement.appendChild(option);
            }
          }

      },[bool,data])


    const busienssChange=(id:any)=>{
        setcsv('')
          setbusinessId(id)
        businesses.map((res:any)=>{
                if(res.businessId===id){
                    setsites(res.sites)
                    try {
                    if(res.sites!==undefined && res.sites.length>0 && data.functionName!=='getCheckInscountReports'){
                    setsiteId(res.sites[0]['siteId'])
                    }else{
                      setsiteId('')

                    }
                    
                      
                  } catch (error) {
                      console.log(error,'site [] erorr');
                      
                  }
                }
        })        
    }


   const siteChange=(id:any)=>{
    setcsv('')
         setsiteId(id)
   } 

   const setrangeDate=(v:any)=>{
    setcsv('') 
let daysxx =91
if(data.functionName==='generateArchivedCheckInsReport'){
  daysxx=31
}

if(data.functionName==='getCheckInscountReports'){
  daysxx=366
}
    const startDate = v[0]
    const endDate = v[1]
    if(endDate && startDate){
        if(diffInMonths(startDate,endDate)>=daysxx){
            alert('Sorry you can download maximun '+daysxx+' days data');
            return false;
          }else{
            setrange(v)
          return true;
      }
          }else{
            setrange(v)
            return;
      }
   }

   const diffInMonths = (d1:any, d2:any) => {
    
  const diff = d2.getTime() - d1.getTime();       
  const daydiff = diff / (1000 * 60 * 60 * 24);   
 return daydiff;
 }

const generateDate=(v:any)=>{
const d=  new Date(v);
const day = d.getDate();
const month =d.getMonth()+1;
const year=  d.getFullYear();


return  `${month}/${day}/${year}`
// return d
}

const getCheckInsReport=async()=>{

     unsubscribe()
    const addMessage = httpsCallable(functions, data.functionName,{ timeout :500000});


    unsubscribe=   onSnapshot(doc(collection(firebase,'reportsProgress'),businessId),{includeMetadataChanges:false}, async(querySnapshot:any) => {    
               
    if(querySnapshot.exists()  && querySnapshot.data().progress!==undefined){
      setprogress(querySnapshot.data().progress)
        if(querySnapshot.data().progress>=100){
          setprogress(0)
        }
    }else{
      setprogress(0)
    }
    });


    if(range[0]!==undefined && range[0]!==null && range[1]!==undefined && range[1]!==null
        && businessId!=='' 
        ){
          setloading(true)
    const startDate = generateDate(range[0]);
    const endDate = generateDate(range[1]);
          console.log(startDate,endDate);
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
      // sites.map((x:any)=>{  
      //     if(x.siteId===siteId) {    
      //     timezone= x.timezone
      //      }

      // })
      let siteIdx:any = siteId
      if(siteId===''){
          siteIdx =null
        }
   await addMessage({businessId,siteId:siteIdx,startDate,endDate,timezone})
    .then(async(result) => {

      proccessSiteAndBusinessPath(businessId,siteId)
             
      const data:any=await result.data;
      console.log(data);
      if(data!=='{"result": null}' && data.length>100 ){
        console.log(data.replace('{"result": "','').replace('}',''),'kookok');
        
            setcsv(data.replace('{"result": "','').replace('}',''))
      }else{
        alert('No data Found')
      }
            setloading(false)
    }).catch((e)=>{
      console.log(e,'erorrr');
      
        setloading(false)
    });
         
        }else{
            alert('Please Select busienss and date range');
        }

   }

const proccessSiteAndBusinessPath=(Bid:any,Sid:any)=>{

  businesses.map((v:any)=>{
    if(v.businessId===Bid){
      setbusinessPath(v.path)
    }
      });

    sites.map((vx:any)=>{
        if(vx.siteId===Sid){
          setsitePath(vx.path)
        }
          });

}


//    void

const generateQuery =async ({
    businessId,
    endDate,
    siteId,
    startDate,
  }: {
    businessId?: string;
    endDate?: string;
    siteId?: string;
    startDate?: string;
  }) => {
    const voidcheckin =collection(firebase,'voidedCheckIns')
    const end = new Date(endDate ? endDate : "");
    const start = new Date(startDate ? startDate : "");
    
    const q = query(voidcheckin,
        where("businessId", "==", businessId),
        where("siteId", "==", siteId),
        where("voidedAt", ">=", start),
        where("voidedAt", "<=", end),
        );
    const querySnapshot = await getDocs(q);
    return querySnapshot;
  };

  
  const getCheckIns = async (query: any) => {
    const snapshot = await query;
    const checkIns: any = [];
    let checkInsRev: any = [];

    await snapshot.forEach((doc:any) => {
      if (doc.exists) {
        const checkInId = doc.id;
        const checkInData = doc.data();

        checkIns.push({ ...checkInData, checkInId });
      }
    });

    checkInsRev = checkIns.reverse();

    return checkInsRev;
};

const convertTimestamp = (timestamp: any) => {
    const date = timestamp.toDate();
    const formatted = date.toLocaleString();
    return formatted;
  };

const processMobile = (mobile: string | null) => {
    if (mobile) {
      if (mobile === "6028181729") {
        return "opted out";
      }
      return mobile;
    }
    return null;
  };

const processCheckIns = (checkIns: any) =>
checkIns.map(
  (checkin:any) => ({
    timestamp: convertTimestamp(checkin.timestamp),    
    location: checkin.sitePath,
    language:checkin.language,
    mobile: checkin.secretMobile || processMobile(checkin.mobile),
    firstname: (checkin.name && checkin.name.firstname) || null,
    lastname: (checkin.name && checkin.name.lastname) || null,
    zip:checkin.zip,
    supercard: checkin.supercard ? checkin.supercard : "",    
    voidedAt: convertTimestamp(checkin.voidedAt),
  })
);


const generateFieldsArray = (businessId:any) => {
       const fields = [
        "Date/Time",
        "Location",
        "Language",
        "Mobile",
        "First Name",
        "Last Name",
        "ZIP Code",
        "supercard",
        "Void At",
      ];

  
    return fields;
  };
  
const generateVoidCheckInsReport = 
async (data: {
  businessId?: any;
  endDate?: any;
  siteId?: any;
  startDate?: any;
}) => {
    if (data.businessId) {
      const query = await generateQuery({
        businessId: data.businessId,
        endDate: data.endDate,
        siteId: data.siteId,
        startDate: data.startDate,
      });

      const checkIns = await getCheckIns(query);
      const processed = processCheckIns(checkIns);

      const dataToConvert = {
        data: processed,
        filename: 'void_CheckIns_Reports',
        delimiter: ',',
        headers: generateFieldsArray(data.businessId)
      }

      const voidCsv =
        processed.length > 0
          ?  csvDownload(dataToConvert)
          : null;

      if (voidCsv) {
        return encodeURI(voidCsv);
      }else{
        alert('Void CheckIns Not Found')
        return null;
      }
  }else{
    return null;
  }
};

const getoidCheckInsReport=(async()=>{
    const endDate =range[1]
    const startDate = range[0];
    setloading(true)
    const dataf={
        businessId,
        endDate,
        siteId,
        startDate
    }    
   await generateVoidCheckInsReport(dataf)
   setloading(false)
})

const validateEmail = (email:String) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const SignUpForEmailReport=async()=>{
if(businessId!=='' &&  Email!=='' && validateEmail(Email)){
  setloading(true)
 const docc = await getDocs(query(collection(firebase,'report-scheduling')))
const arr = docc.docs.filter((v)=>{
      try {
        const r= v.data().map;
        if(r.businessId===businessId && r.siteId===siteId && r.email===Email && r.type===ReportOf &&  r.occurence===Period){
           return true
        }else{
          return false
        }

      } catch (error) {
          console.log(error);  
          return false  
      }
 })

  if(arr.length<=0){
const  map = {
    active:true,
    businessId,
    siteId,
    email:Email,
    type:ReportOf,
    occurence:Period
  }
  await addDoc(collection(firebase, "report-scheduling"), 
    {
      map
  }
  ).catch((e)=>{
    console.log(e);             
  }).then(async(i:any)=>{
    setloading(false)
    alert('Successfully Added Report Schedule')
                     
  })
}else{
  alert('busienss and  site and email already exist.');
  setloading(false)
}
}else{
  alert('busienss and  site and email are required.')
  setloading(false)
}


}


const getCheckInsReportcount=async()=>{

 console.log(range,businessId);

 if(businessId!=='' && siteId!==''){
 settableloading(true)
 setdatesarray([''])

 const addMessage = httpsCallable(functions,data.functionName,{ timeout :500000});
 await addMessage({businessId,siteId,selectedmonth})
  .then(async(result) => {

    console.log(result.data,'>>>>>>>>');
     setdatesarray(result.data)
 setdatesarrayinit(result.data)

 setTimeout(() => {
    groupBy('month',result.data)
    settableloading(false)
 }, 1000);
  });
 }else{
  alert('Please select business and site.')
 }


}

const groupBy=(va:any,arr:any)=>{
  const final:any= [];


if(va=='month' || va=='year'){
    const grouped = arr.reduce((acc:any, item:any) => {
     
      
      const key = va=='year' ? item.year+'-'+item.SitePath :  item.month+'-'+item.SitePath;
      if (!acc[key]) {
        acc[key] = []; // Initialize group if not exists
      }
      acc[key].push(item);
      return acc;
    }, {});
 
    Object.keys(grouped).map((val:any)=>{


      const arr = {
        BusinessPath:"",
        LastUpdatedDate:'',
        NextUpdateDate:'',
        SitePath:"",
        cash4cars:0,
        dd:"",
        month:'',
        year:'',
        firstresponder:0,
        new:  0,
        optout:  0,
        recheck : 0,
        smssentjustIn:0,
        smssent:0,
        mmssent:0,
        smssentkiosken: 0,
        smssentkioskspanish:0,
        theft:0,
        timestamp:0,
        total:0,
        void:0,
        _return:0}

      grouped[val].map((item:any)=>{
       
        arr.BusinessPath=item.BusinessPath
        arr.LastUpdatedDate=item.LastUpdatedDate
        arr.NextUpdateDate=item.NextUpdateDate
        arr.SitePath=item.SitePath
        arr._return+=item._return
        arr.cash4cars+=item.cash4cars
        arr.dd=`${item.year}/${item.month}`
        arr.month=val
        arr.year=val
        arr.firstresponder+=item.firstresponder
        arr.new+=item.new
        arr.optout+=item.optout
        arr.recheck+=item.recheck
        arr.smssent+=item.smssent
        arr.mmssent+=item.mmssent
        arr.smssentkiosken+=item.smssentkiosken
        arr.smssentkioskspanish+=item.smssentkioskspanish
        arr.theft+=item.theft
        arr.timestamp=item.timestamp
        arr.total+=item.total
        arr.void+=item.void

      })

      final.push(arr)
      
    })

    setdatesarray(final)

  }else{
  setdatesarray(datesarrayinit)
  }

    
    


}


const csvDownloads=()=>{
const datas = datesarray;
const final:any =[]
const finalx:any = []
const das = new Date();
finalx.push([
  'Last Updated:',
  `${das.getMonth()+1}/${das.getDate()}/${das.getFullYear()}`,
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',    
  '',
  '',
  ''
])

const date =  new Date()
 date.setDate(date.getDate()+1)
finalx.push([
  'Next Update Scheduled:',
  `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`,
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',    
  '',
  '',
  ''
])

   finalx.push([
    'Date',
    'Business',
    'Site',
    'Total Checkins (new+return+optout+void)',
    'Total SMS Credits Used (SMS+MMS)',
    'New',
    'Return',
    'Opt-Out',
    'Void',
    'Recheck',
    'Cash For Cars',
    'First Responder',
    'Theft',    
    'Total SMS Credits Sent',
    'Total MMS Credits Sent',
    'MMS converted to SMS'
  ])
  datas.map((v:any)=>{
        console.log(v,'');
        finalx.push([
      v.dd,
      v.BusinessPath,
      v.SitePath,
      v.total,
      v.smssent+(v.mmssent*2),
      v.new,
      v._return,
      v.optout,
      v.void,
      v.recheck,
      v.cash4cars,
      v.firstresponder,
      v.theft,     
      v.smssent,
      v.mmssent,
      (v.mmssent*2),
    ])      
  })

  final.push(finalx)
  const data = final;
  const finalarr:any = [];
  data.map((x:any)=>{
      x.map((nx:any)=>{
          finalarr.push(nx)
      })
  
  })


const seperator = ",";


const csv = [
    ...finalarr.map((row:any )=>  [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14].map(field => `${row[field]}`).join(seperator))
]

//Export our csv in rows to a csv file
let csvContent = "data:text/csv;charset=utf-8," 
    + csv.join("\n");
var encodedUri = encodeURI(csvContent);
const url = encodedUri;
const link = document.createElement('a');
link.href = url;
link.download = 'Summary-report.csv';
link.click();
URL.revokeObjectURL(url);



}


const today = new Date();

// Add 1 day to today's date
const tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);





return(
    <div>
        <div style={{display:data.functionName!=='reportToMail' ? window.innerWidth<=720 ?'grid' : 'flex' : 'inline-table'}}>
        <select className='selectInput' onChange={(e)=>busienssChange(e.target.value)}><option value={''}>select business</option> {businesses.map((val:any,i:any)=>(val.sites!==undefined && val.sites.length>0 ?<option key={i} value={val.businessId}>{val.businessName}</option>:''))}</select>
        <select className='selectInput'  onChange={(e)=>siteChange(e.target.value)}><option value={''}>select site</option> 
        {sites.length>1?<option  value={''}>all</option>:''}
        {sites.map((val:any,i:any)=>((val.isArchive===undefined || val.isArchive===0 || val.isArchive==='0') ?  <option key={i} value={val.siteId}>{val.path}</option>:''))}
        </select>
        {data.functionName!=='getCheckInscountReports' &&  data.functionName!=='reportToMail'?
        <DateRangeInput  
        popoverProps={{ position: 'auto' }}                   
                   {...getDateFnsFormatter("MM/dd/yyyy")}
                   allowSingleDayRange={true} 
                   shortcuts={false}
                   
                    value={range}                
                    onChange={(range:any)=>setrangeDate(range)}
                />
                : data.functionName=='getCheckInscountReports' ?
              
                <select className='selectInput'  id="last-12-months" onChange={(e)=>setselectedmonth(e.target.value)} name="last-12-months"></select>

                :''
                } 
    {data.functionName!=='reportToMail'? <span>&nbsp;
       {csv==='' ? 
       <ButtonGroup>
       {<Button className='buttonInput'  loading={loading} onClick={()=>data.functionName!=='voidCheckins' &&  data.functionName!=='getCheckInscountReports' ? getCheckInsReport():  data.functionName=='getCheckInscountReports' ?  getCheckInsReportcount() : getoidCheckInsReport()}>{data.functionName=='getCheckInscountReports' ? 'generate report':'get report '}&nbsp;&nbsp;&nbsp;<Icon icon='globe-network'  color='#fff' size={15}/>&nbsp;</Button>}
  
{loading && progressx>0 && progressx<100?<Button className='buttonInputX' intent={progressx>=10 && progressx<=30 ? 'primary':  progressx>=30 && progressx<=40 ? 'none': progressx>=40 && progressx<=60 ? 'warning' : progressx>=60 && progressx<=100 ? 'success' :'primary'} small={true}>{progressx}%</Button>:''}
       </ButtonGroup>:
         <AnchorButton
         download={`${data.Name}-${businessPath}-${sitePath}-${generateDate(range[0])}-to-${generateDate(range[1])}.csv`}
         href={`data:text/csv;charset=utf-8,${csv}`}
         intent="success"
         rightIcon="download"
         loading={loading}
        //  minimal={true}
         
       >
         download csv
       </AnchorButton>
       }
       </span>       
       :''}  

{data.functionName==='reportToMail'? 
  <InputGroup
  type="email"
   disabled={false}
   large={false}
   placeholder="Enter email..."
   readOnly={false}
   onChange={(f:any)=>setEmail(f.target.value) }
/>
:''}
{data.functionName==='reportToMail'?
 <select className='selectInput'  onChange={(e)=>setReportOf(e.target.value)}>
  <option value={'vehicle-picks'}>Vehicle Picks Reports</option>
  <option value={'check-ins'}>Check Ins Reports</option>
  </select>:''}
  {data.functionName==='reportToMail'? <select className='selectInput'  onChange={(e)=>setPeriod(e.target.value)}>
      <option value="weekly">Weekly (every Mon)</option>
      <option value="monthly">Monthly (every 1st)</option>
      <option value="daily">Daily</option>
  </select>:''}

  {data.functionName==='reportToMail'?
  <Button className='buttonInput' onClick={()=>SignUpForEmailReport()} loading={loading} >Sign Up</Button>
      :''}
        </div>


        <br/><br/><br/>

        {datesarray.length>0 ? tableloading==false ?
        <div>
{/* <select className='selectInput' onChange={(e)=>groupBy(e.target.value,datesarray)}>
   <option value={'day'} selected>Group By Day</option>
   <option value={'month'} selected>Group By Month</option>
   <option value={'year'}>Group By Year</option>
</select> */}

<Button className='selectInput' style={{color:'white',marginBottom:''}} onClick={()=>csvDownloads()}>CSV Download</Button>
<p><b>Last Updated: {new Date().toLocaleDateString()}</b></p>
<p><b>Next Update: {tomorrow.toLocaleDateString()}</b></p>
<HTMLTable  border={1}>
<tr>
    <th>Date</th>
    <th>Business</th>
    <th>Site</th>
    <th>Total Checkins (new, return, optout, void)</th>
    <th>Total SMS Credits Used (SMS & MMS)</th>
    <th>New</th>
    <th>Return</th>
    <th>Opt-Out</th>
    <th>Void</th>
    <th>Recheck</th>
    <th>Cash For Cars</th>
    <th>First Responder</th>
    <th>Theft</th>


    <th>Total SMS Credits Sent</th>
    <th>Total MMS Credits Sent</th>
    <th>MMS converted to SMS</th>
  </tr>
  <tbody>

  {datesarray.map((xy:any,inx:any)=>
  <tr>
    <td>{xy.dd}</td>
    <td>{xy.BusinessPath.toUpperCase()}</td>
    <td>{xy.SitePath.toUpperCase()}</td>
    <td>{xy.total}</td>    
    <td>{xy.smssent+(xy.mmssent*2)}</td>
    <td>{xy.new}</td>
    <td>{xy._return}</td>
    <td>{xy.optout}</td>
    <td>{xy.void}</td>
    <td>{xy.recheck}</td>
    <td>{xy.cash4cars}</td>
    <td>{xy.firstresponder}</td>
    <td>{xy.theft}</td>
  
    <td>{xy.smssent}</td>
    <td>{xy.mmssent}</td>
    <td>{xy.mmssent*2}</td>

  </tr>
)}
  </tbody>
</HTMLTable></div>: <Spinner intent={'primary'} size={30}/> :''}
<br/><br/><br/>
    </div>

    
)




}