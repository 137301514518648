import React, { useEffect, useState }  from 'react';
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { UsersList } from '../pages/users';
import { Businesses } from '../pages/Businesses';
import ArchiveCheckIn from '../pages/ArchiveCheckIns';
import AlarmReport from '../pages/AlarmReport';
import CashiersGmView from '../pages/CashierGmView/CashierGmView';
import ReportScheduling from '../pages/ReportScheduling';
import Reports from '../pages/Reports';
import Checkout from '../pages/Checkout/checkout';
import { CheckInHistory } from '../pages/CheckIns/CheckInHistory';
import { JustIn } from '../pages/justIn';
import Points from '../pages/points/points';
import Dashboard from '../pages/Dashboard';
import JustInMulticationLocation from '../pages/JustInMulticationLocation/JustInMulticationLocation';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firebase } from '../global/firebase';
import Unsubscribed from '../pages/Unsubscribed';
import { CustomerInfo } from '../pages/LocationCouponData/customerinfo';
import { LocationCouponData } from '../pages/LocationCouponData/LocationCouponData';
import Pointsystemquery from '../pages/pointsystemquery';
import BusinessGroups from '../pages/BusinessGroups';

export const AllRoutes = ()=>{

    const auth:any = useAuthUser();
    const isAuthenticated = useIsAuthenticated();    
    const [routeArr,setRouteArr]=useState([{}]);
    const [multilocationsmsnotification,setmultilocationsmsnotification]=useState(false);

  
    useEffect(() => {
          


        try {
        const path =window.location.pathname.split('/')[2]
        getDocs(query(collection(firebase,'businesses'),where('path','==',path))).then((r)=>{
            const rr =r.docs[0].data();
            if(rr.multilocationsmsnotification!==undefined && rr.multilocationsmsnotification===true){
                setmultilocationsmsnotification(true)
            }else{
                setmultilocationsmsnotification(false)
            }   
    
           }).catch((error)=>{
            console.log(error);
            
           });
           
            
        } catch (error) {
            console.log(error);
            
        }
    const routex = [
        {
            component:<Login/>, 
            icon:'login',
            path:'/',
            name:'',
            auth:false,
            menu:false
    },  
    {
        component:<Unsubscribed/>, 
        icon:'unsubscribed',
        path:'/unsubscribe-from-reports',
        name:'',
        auth:false,
        menu:false
},   
    {
        component: (multilocationsmsnotification!==undefined && multilocationsmsnotification===true ?   <JustInMulticationLocation /> :  <JustIn/>), 
        icon:'JustIn',
        path:'/just-in/:business?/:site?/:mobile?',
        name:'',
        auth:false,
        menu:false
},]
    let StafRoutes:any =[];
    let OwnerRoutes:any =[];
    let AdminRoutes:any =[];
    let AdminRoutesx:any=[];

    if(isAuthenticated()){
    let role = '';
    let HideCheckinHistory=false;
    let HideTodayscheckin = false; 
    let HideCheckoutpage  = false; 
    let dashboardPage  = false;
    let pointsystemquery=false;
    try {
        role = auth().accountData.permissions.role;            
        HideCheckinHistory = auth().accountData.permissions.HideCheckinHistory;  
        HideTodayscheckin = auth().accountData.permissions.HideTodayscheckin; 
        HideCheckoutpage  = auth().accountData.permissions.HideCheckoutpage; 
       
        try {
            if(auth().accountData.permissions.isDashboard!==undefined && auth().accountData.permissions.isDashboard===true){
                dashboardPage=true
            }
            if( auth().accountData.permissions.pointsystemquery!==undefined &&  auth().accountData.permissions.pointsystemquery===true){
              pointsystemquery= auth().accountData.permissions.pointsystemquery;
            }
        } catch (error) {
            console.log(error);
            
        }
    } catch (error) {
console.log(error);

    window.location.href = '/'
    }  
    if(role==='admin' || role==='staff' || role==='owner'){  
    StafRoutes = [
        {
            component:<Home/>, 
            icon:'home',
            path:'/home',
            name:'Home',
            auth:true,
            menu:true,
            homeGrid:false
        },


        {
            component:<Pointsystemquery/>, 
            icon:'graph',
            path:'/points-system-dashboard',
            name:'Point-System-Dashboard',
            auth:true,
            menu:true,
            homeGrid:false
        },
        {              
            component:<LocationCouponData/>, 
            icon:'briefcase',
            path:'/LocationCouponData',
            name:'LocationCouponData',
            auth:true,
            menu:false,
            homeGrid:false,
            description:''
        },
        {              
            component:<CustomerInfo/>, 
            icon:'briefcase',
            path:'/CustomerInfo',
            name:'CustomerInfo',
            auth:true,
            menu:false,
            homeGrid:false,
            description:''
        },

        {
            component:<Dashboard/>, 
            icon:'Dashboard',
            path:'/Dashboard',
            name:'Dashboard',
            auth:true,
            menu:true,
            homeGrid:false
        },
    

        
        {
            component:<CheckInHistory/>, 
            icon:'saved',
            path:'/Check-In-History',
            name:'Check-In History',
            auth:true,
            menu:true,
            homeGrid:true,
            description:'Track your daily customer attendance'
        },
        // {
        //     component:<Checkout/>, 
        //     icon:'saved',
        //     path:'/Check-out',
        //     name:'Check-out',
        //     auth:true,
        //     menu:true,
        //     homeGrid:true,
        //     description:'check out daily customer attendance'
        // },
    {
        component:<CashiersGmView/>, 
        icon:'filter-list',
        path:'/Todays-Check-Ins',
        name:'Todays-Check-Ins',
        auth:true,
        menu:true,
        homeGrid:false
    }, ] 
    }   

    if(role==='owner' || role==='admin'){
    OwnerRoutes=[
        {
            component:<Reports/>, 
            icon:'chart',
            path:'/Reports',
            name:'Reports',
            auth:true,
            menu:true,
            homeGrid:true,
            description:'Signup for Check-ins and Vehical Picks Reports'
        },
  ]
     }
    if(role==='admin'){ 
    AdminRoutes=[


        {              
            component:<Points/>, 
            icon:'briefcase',
            path:'/Points',
            name:'Points',
            auth:true,
            menu:true,
            homeGrid:false,
            description:''
        },

  
    {
        component:<Businesses/>, 
        icon:'briefcase',
        path:'/Businesses',
        name:'Businesses',
        auth:true,
        menu:true,
        homeGrid:true,
        description:''
    },
    {
        component:<UsersList/>, 
        icon:'user',
        path:'/Users',
        name:'Users',
        auth:true,
        menu:true,
        homeGrid:true,
        description:''
    },
 
    // {
    //     component:<AlarmReport/>, 
    //     icon:'stopwatch',
    //     path:'/Alarm-Report',
    //     name:'Alarm-Report',
    //     auth:true,
    //     menu:true,
    //     homeGrid:false
    // },
    {
        component:<ArchiveCheckIn/>, 
        icon:'archive',
        path:'/Archive-CheckIn',
        name:'Archive-CheckIn',
        auth:true,
        menu:true,
        homeGrid:false
    },

  
] 
     } 

     if(role==='admin'){ 
        AdminRoutesx=[
    
    
            {
                component:<ReportScheduling/>, 
                icon:'graph',
                path:'/Scheduled-reports',
                name:'Scheduled-reports',
                auth:true,
                menu:true,
                homeGrid:true,
                description:''
            },
        
            {              
                component:<BusinessGroups/>, 
                icon:'business',
                path:'/Business-Groups',
                name:'Business-Groups',
                auth:true,
                menu:true,
                homeGrid:false,
                description:''
            },
    
        ]
        }
     if(HideCheckinHistory===true){
        StafRoutes =  StafRoutes.filter((x:any)=>(x.name!=='Check-In History'))
    }
    if(HideTodayscheckin===true){
        StafRoutes =  StafRoutes.filter((x:any)=>(x.name!=='Todays-Check-Ins'))
    }

    if(HideCheckoutpage===true){
        StafRoutes =  StafRoutes.filter((x:any)=>(x.name!=='Check-out'))
    }
if(dashboardPage===false){
    StafRoutes =  StafRoutes.filter((x:any)=>(x.name!=='Dashboard'))
}

if(pointsystemquery===false){
    StafRoutes =  StafRoutes.filter((x:any)=>(x.name!=='Point-System-Query'))
}
     
    }

    const routt = [...StafRoutes,...AdminRoutes,...OwnerRoutes,...AdminRoutesx,...routex]
    setRouteArr(routt) 

},[multilocationsmsnotification]);

    
    return routeArr       
    //          <Routes>
    //   {routeArr.map((r:any,index:any)=>(  <Route key={index} path={r.path} element={
    //     r.auth ? 
    //     <RequireAuth loginPath={'/'}>
    //     <Home/>
    //     </RequireAuth>:
    //     r.component
    //     }/>))}
       
    //   </Routes> 
    
 
}