import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { BusinessesCollection, firebase, urls } from "../global/firebase";
import { Button,  Checkbox, Classes, Dialog, H1, H3, H5, H6, InputGroup, Label, Radio, RadioGroup, Slider, Switch, TagInput, Tooltip } from "@blueprintjs/core";
import { CopyLink } from "./Copylink";
import QRCode from "qrcode.react";
import {Businesses} from '../interfaces/businesses';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { InputMask } from '@react-input/mask';
export  const BusinessForm=(data:any)=>{
    const defaultcheckinoption={
        bannedCusotmerTableRowBgColor:'',bannedCusotmerTableRowFontColor:'',buttonColor:'',leftSideBgColor:'',
     leftSideTagColor:'',mainBackgroundColor:'',tableBackgroundColor:'',tableBorderColor:'',tableFontColor:'',
 }
      const [currentBusiness,SetCurrentBusiness]=useState([] as any)
      const [addnewkiosk,setaddnewkiosk]=useState(false)
    //   variables
    const [Updatelogo,setUpdatelogo]=useState(false);
    const [smsgroup,setsmsgroup]=useState([] as any);
    const [businesstoken,setbusinesstoken]=useState('');
    const [CheckedIdOption,setCheckedIdOption]=useState(false)
    const [stockInventoryRules,setstockInventoryRules]=useState('')
    const [inventoryNotificationEmails,setinventoryNotificationEmails]=useState([] as any);
    const [inventoryNotification,setinventoryNotification] =useState(false);
    const [FeedAlertDays,setFeedAlertDays]=useState('');
    const [StockImageUrl,setStockImageUrl]=useState('');
    const [addaNewPickSwitch,setaddaNewPickSwitch]=useState(false);
    const [showPickLeft,setshowPickLeft]=useState(false)
    const [bannedCustomerOption,setbannedCustomerOption]=useState(false);
    const [bannedWarningAlert,setbannedWarningAlert]=useState(false);
    const [businessId,setbusinessId]=useState('');
    const [businessName,setbusinessName]=useState('');
    const [checkInsOptions,setcheckInsOptions]=useState(defaultcheckinoption);
    const [Disabled,setDisabled]=useState(false);
    const [colormode,setcolormode]=useState(false);
    const [darkmode,setdarkmode]=useState(false);  
    const [enableRowInTxtMsgSwitch,setenableRowInTxtMsgSwitch]=useState(false);
    const [feedType,setfeedType]=useState('');
    const [guid,setguid]=useState('');
    const [inventoryEmailControl,setinventoryEmailControl]=useState(false);
    const [justInKeyword,setjustInKeyword]=useState('');
    const [keyword,setkeyword]=useState('');
    const [multilocationsmsnotification,setmultilocationsmsnotification]=useState(false);
    const [langEnglish,setlangEnglish]=useState(false);
    const [langSpanish,setlangSpanish]=useState(false);
    const [markForSellingCarSwitch,setmarkForSellingCarSwitch]=useState(false);
    const [markForTheftSwitch,setmarkForTheftSwitch]=useState(false);
    const [militaryFirstResopnderSwitch,setmilitaryFirstResopnderSwitch]=useState(false);
    const [modelYearRangeSwitch,setmodelYearRangeSwitch]=useState(false);
    const [path,setpath]=useState('');
    const [proMembership,setproMembership]=useState(false);
    const [s3Id,sets3Id]=useState('');
    const [urgId,seturgId]=useState('');
    const [sensitiveDataMaskingSwitch,setsensitiveDataMaskingSwitch]=useState(false);
    const [shortcode,setshortcode]=useState('');
    const [sites,setsites]=useState([] as any);
    const [themee,settheme]=useState({} as any);
    const [yardName,setyardName]=useState('');
    const [proMembershipFreeEntry,setproMembershipFreeEntry]=useState(false)
    const [militaryFirstResopnderSwitchFreeEntry,setmilitaryFirstResopnderSwitchFreeEntry]=useState(false)
    const [todaysCouponOption,settodaysCouponOption]=useState(false)
    const [todaysallTabOption,settodaysallTabOption]=useState(false)
    
    const [yardsmartfeed,setyardsmartfeed]=useState(false)
    const [ysfAccessToken,setysfAccessToken]=useState('');
    const [yardsmartid,setyardsmartid]=useState('');
    const [emailinreport,setemailinreport]=useState(false)


    // New Site States
    const [loading,setloading]=useState(false)
    const[SiteMsg,setSiteMsg]=useState('')
    const [allowOptOut,setallowOptOut]=useState(true)
    const [autoredirectCheckInFinish,setautoredirectCheckInFinish]=useState(false)
    const [disableKeyboard,setdisableKeyboard]=useState(false)
    const [sguid,setsguid]=useState(null)
    const [skeyword,setskeyword]=useState(null)
    const [keywordEs,setkeywordEs]=useState(null)
    const [kioskName,setkioskName]=useState(null)
    const [kioskNameEs,setkioskNameEs]=useState(null)
    const [kioskUiliability,setkioskUiliability]=useState(null)
    const [mobileValidation,setmobileValidation]=useState(null)
    const [noCheckIn,setnoCheckIn]=useState(false)
    const [spath,setspath]=useState('')
    const [ss3Id,setss3Id]=useState(null)
    const [sshortcode,setsshortcode]=useState(null)
    const [showCoupons,setshowCoupons]=useState(false)
    const [siteId,setsiteId]=useState(null)
    const [siteName,setsiteName]=useState(null)

    const [Tooltips,setTooltip]=useState([] as any)

    const [routes,setroutes]=useState({
        covidquestions:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        customertype:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        dateofbirth:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        email:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        language:{
            alwaysShow:true,
            enabled:true,
            optional:false,
        },
        name:{
            alwaysShow:true,
            enabled:true,
            optional:false,
        },
        optin:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        signature:{
            alwaysShow:true,
            enabled:true,
            optional:false,
            takeMugshot:true,
            waiverText:'',
            waiverTextEs:'',
        },
        supercard:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        thanks:{
            timeout:1000
        },
        vehicles:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
        zip:{
            alwaysShow:false,
            enabled:false,
            optional:false,
        },
    })

    useEffect(()=>{ 
 
        getBusiness();

    },[data,Disabled]);

    const getBusiness=async()=>{      
        setUpdatelogo(false)

        const tips =await getDoc(doc(collection(firebase,'toolTips'),'business')) 
        if(tips!==undefined && tips.exists()){
          setTooltip(tips.data())
        }
        const docRef = doc(BusinessesCollection, data.payload.id);
        const docSnap:Businesses.Business = (await getDoc(docRef)).data() as Businesses.Business;  
        const sites=await getDocs(query(collection(firebase,`businesses/${data.payload.id}/sites`)))  
        const arrs:any=[] 
            if(sites.empty===false){                
                sites.docs.map((v:any)=>{
                    arrs.push(v.data())
                })
            }
            
            
        SetCurrentBusiness(docSnap)
        if(docSnap.smsgroup!==undefined){ setsmsgroup(docSnap.smsgroup) }else{ setsmsgroup([])}
        if(docSnap.FeedAlertDays!==undefined){ setFeedAlertDays(docSnap.FeedAlertDays) }else{  setFeedAlertDays('') }
        if(docSnap.businesstoken!==undefined){setbusinesstoken(docSnap.businesstoken)}else{setbusinesstoken('')}
        if(docSnap.StockImageUrl!==undefined){ setStockImageUrl(docSnap.StockImageUrl) }else{ setStockImageUrl('')}
        if(docSnap.CheckedIdOption!==undefined){ setCheckedIdOption(docSnap.CheckedIdOption) }else{ setCheckedIdOption(false) }
        if(docSnap.stockInventoryRules!==undefined){ setstockInventoryRules(docSnap.stockInventoryRules) }else{ setstockInventoryRules('')}
        if(docSnap.inventoryNotificationEmails!==undefined){ setinventoryNotificationEmails(docSnap.inventoryNotificationEmails) }else{ setinventoryNotificationEmails([])}
        if(docSnap.inventoryNotification!==undefined){ setinventoryNotification(docSnap.inventoryNotification) }else{  setinventoryNotification(false)}
        if(docSnap.addaNewPickSwitch!==undefined){ setaddaNewPickSwitch(docSnap.addaNewPickSwitch) }else{ setaddaNewPickSwitch(false) }
        if(docSnap.showPickLeft!==undefined){ setshowPickLeft(docSnap.showPickLeft) }else{ setshowPickLeft(false) }
        if(docSnap.bannedCustomerOption!==undefined){ setbannedCustomerOption(docSnap.bannedCustomerOption) }else{  setbannedCustomerOption(false) }
        if(docSnap.bannedWarningAlert!==undefined){ setbannedWarningAlert(docSnap.bannedWarningAlert) }else{ setbannedWarningAlert(false)  }
        if(docSnap.businessId!==undefined){ setbusinessId(docSnap.businessId) }else{  setbusinessId('') }
        if(docSnap.businessName!==undefined){ setbusinessName(docSnap.businessName) }else{  setbusinessName('') }
        if(docSnap.colormode!==undefined){ setcolormode(docSnap.colormode) }else{ setcolormode(false)}
        if(docSnap.darkmode!==undefined){ setdarkmode(docSnap.darkmode) }else{  setdarkmode(false) }
        if(docSnap.enableRowInTxtMsgSwitch!==undefined){ setenableRowInTxtMsgSwitch(docSnap.enableRowInTxtMsgSwitch) }else{  setenableRowInTxtMsgSwitch(false)  }
        if(docSnap.feedType!==undefined){ setfeedType(docSnap.feedType) }else{ setfeedType('') }
        if(docSnap.guid!==undefined){ setguid(docSnap.guid) }else{  setguid('')  }
        if(docSnap.inventoryEmailControl!==undefined){ setinventoryEmailControl(docSnap.inventoryEmailControl) }else{ setinventoryEmailControl(false) }
        if(docSnap.justInKeyword!==undefined){ setjustInKeyword(docSnap.justInKeyword) }else{  setjustInKeyword('') }
        if(docSnap.keyword!==undefined){ setkeyword(docSnap.keyword) }else{ setkeyword('') }
        if(docSnap.langEnglish!==undefined){ setlangEnglish(docSnap.langEnglish) }else{ setlangEnglish(false) }
        if(docSnap.langSpanish!==undefined){ setlangSpanish(docSnap.langSpanish) }else{  setlangSpanish(false)}
        if(docSnap.markForSellingCarSwitch!==undefined){ setmarkForSellingCarSwitch(docSnap.markForSellingCarSwitch) }else{  setmarkForSellingCarSwitch(false) }
        if(docSnap.markForTheftSwitch!==undefined){ setmarkForTheftSwitch(docSnap.markForTheftSwitch) }else{ setmarkForTheftSwitch(false)}
        if(docSnap.militaryFirstResopnderSwitch!==undefined){ setmilitaryFirstResopnderSwitch(docSnap.militaryFirstResopnderSwitch) }else{ setmilitaryFirstResopnderSwitch(false) }
        if(docSnap.modelYearRangeSwitch!==undefined){ setmodelYearRangeSwitch(docSnap.modelYearRangeSwitch) }else{ setmodelYearRangeSwitch(false) }
        if(docSnap.path!==undefined){ setpath(docSnap.path)}else{  setpath('')}
        if(docSnap.proMembership!==undefined){ setproMembership(docSnap.proMembership) }else{ setproMembership(false) }
        if(docSnap.s3Id!==undefined){ sets3Id(docSnap.s3Id) }else{ sets3Id('')  }
        if(docSnap.sensitiveDataMaskingSwitch!==undefined){ setsensitiveDataMaskingSwitch(docSnap.sensitiveDataMaskingSwitch) }else{ setsensitiveDataMaskingSwitch(false) }
        if(docSnap.shortcode!==undefined){ setshortcode(docSnap.shortcode) }else{ setshortcode('')}
        if(arrs.length>0){ setsites(arrs) }
        if(docSnap.theme!==undefined){ settheme(docSnap.theme) }else{ settheme({})}
        if(docSnap.yardName!==undefined){ setyardName(docSnap.yardName) }else{  setyardName('') }
        if(docSnap.urgId!==undefined){ seturgId(docSnap.urgId) }else{  seturgId('')  }
        if(docSnap.checkInsOptions!==undefined){ setcheckInsOptions(docSnap.checkInsOptions) }else{ setcheckInsOptions(defaultcheckinoption) }
       if(docSnap.multilocationsmsnotification!==undefined){ setmultilocationsmsnotification(docSnap.multilocationsmsnotification)}else{ setmultilocationsmsnotification(false)}
        if(docSnap.todaysCouponOption!==undefined){ settodaysCouponOption(docSnap.todaysCouponOption) }else{ settodaysCouponOption(false) }
        if(docSnap.todaysallTabOption!==undefined){ settodaysallTabOption(docSnap.todaysallTabOption) }else{ settodaysallTabOption(false)}
        if(docSnap.emailinreport!==undefined){ setemailinreport(docSnap.emailinreport) }else{ setemailinreport(false)}

        if(docSnap.todaysallTabOption!==undefined){ settodaysallTabOption(docSnap.todaysallTabOption) }else{ settodaysallTabOption(false)}
        if(docSnap.todaysallTabOption!==undefined){ settodaysallTabOption(docSnap.todaysallTabOption) }else{ settodaysallTabOption(false)}
        if(docSnap.yardsmartid!==undefined){ setyardsmartid(docSnap.yardsmartid) }else{ setyardsmartid('')}
        if(docSnap.ysfAccessToken!==undefined){ setysfAccessToken(docSnap.ysfAccessToken) }else{ setysfAccessToken('')}
        if(docSnap.yardsmartfeed!==undefined){ setyardsmartfeed(docSnap.yardsmartfeed) }else{ setyardsmartfeed(false)}

        
    }

    const HelpElement = (text:any)=>{
  
        if(text!==undefined && text.text!==undefined){
        return (

      <Tooltip  content={text.text}  position="top">
      <Button  icon={'help'} small={true} />
                          </Tooltip>
                 
      )
        }else{
          return(<span/>)
        }
       }

       const Handlesetroutes=(name:string,name2:string,value:any)=>{
        switch (name) {
            case 'signature':                
                switch (name2) {
                    case 'always':
                        routes.signature.alwaysShow=value                        
                    break; 
                    case 'enabled':
                        routes.signature.enabled=value
                    break; 
                    case 'optional':
                        routes.signature.optional=value
                    break; 
                    case 'takeMugshot':
                        routes.signature.takeMugshot=value
                    break;                     
                    case 'waiverText':
                        routes.signature.waiverText=value
                    break; 
                    case 'waiverTextEs':
                        routes.signature.waiverTextEs=value
                    break; 
                }
            break;
            case 'dateofbirth':                
                    switch (name2) {
                        case 'always':
                            routes.dateofbirth.alwaysShow=value                        
                        break; 
                        case 'enabled':
                            routes.dateofbirth.enabled=value
                        break; 
                        case 'optional':
                            routes.dateofbirth.optional=value                
                        break; 
                    }
        break; 
        case 'email':                
                switch (name2) {
                    case 'always':
                        routes.email.alwaysShow=value                        
                    break; 
                    case 'enabled':
                        routes.email.enabled=value
                    break; 
                    case 'optional':
                        routes.email.optional=value                
                    break; 
                }
        break; 
        case 'language':                
                switch (name2) {
                case 'always':
                    routes.language.alwaysShow=value                        
                break; 
                case 'enabled':
                    routes.language.enabled=value
                break; 
                case 'optional':
                    routes.language.optional=value                
                break; 
                }
        break; 
        case 'name':                
                switch (name2) {
                case 'always':
                    routes.name.alwaysShow=value                        
                break; 
                case 'enabled':
                    routes.name.enabled=value
                break; 
                case 'optional':
                    routes.name.optional=value                
                break; 
                }
       break; 
       case 'vehicles':                
                switch (name2) {
                case 'always':
                    routes.vehicles.alwaysShow=value                        
                break; 
                case 'enabled':
                    routes.vehicles.enabled=value
                break; 
                case 'optional':
                    routes.vehicles.optional=value                
                break; 
                }
        break; 
        case 'zip':                
                switch (name2) {
                    case 'always':
                        routes.zip.alwaysShow=value                        
                    break; 
                    case 'enabled':
                    routes.zip.enabled=value
                    break; 
                    case 'optional':
                    routes.zip.optional=value                
                    break; 
                }
        break; 
       }
    setroutes(routes)    
    updateQueryFirebaseBusienss({checkInsOptions})
    }

    const convertToPath = (name: string) =>
    name
    .replace("&", "and")
    .replace(/[^\w-\s]/g, "")
    .replace(/\s/g, "-")
    .replace(/-{2,}/g, "-")
    .toLowerCase();

    const addNewSite=async()=>{
        setloading(true)
        setSiteMsg('')   
        if(kioskName!=='' && kioskName!==null && siteName!=='' && siteName!==null && kioskNameEs!=='' && kioskNameEs!==null){
            await addDoc(collection(firebase, "businesses/"+businessId+'/sites'), {
                routes,
                siteName,
                showCoupons,
                businessId,
                shortcode:sshortcode,
                s3Id:ss3Id,
                path:spath,
                noCheckIn,
                mobileValidation,
                kioskUiliability,
                kioskNameEs,
                kioskName,
                keywordEs,
                skeyword,
                guid:sguid,
                disableKeyboard,
                autoredirectCheckInFinish,
                kioskUiHideContBtn:true,
                kioskUiTermTextSize:'10px',
                allowOptOut,
                    darkmode:true,
                    bannedCustomerOption:true,
                    markForTheftSwitch:true,
                    militaryFirstResopnderSwitch:true,
                    markForSellingCarSwitch:true,
                    kioskUiHideCity:true
              }).catch(()=>{         
              }).then(async(i:any)=>{
                console.log("Document written with ID:"+i.id);
                await updateDoc(doc(firebase, "businesses/"+businessId+'/sites', i.id), {siteId:i.id})
                    .then(docRef => {
                        console.log("A New Document Field has been added to an existing document");
                        window.location.href= '';
                        setloading(false)
                    })   
                    
                // fs9BusinessNameToUidLookup/fs10SiteNameToUidLookup
            try {
                await setDoc(doc(firebase, `fs9BusinessNameToUidLookup/${path}/fs10SiteNameToUidLookup`, spath), {siteName,siteId:i.id}).catch((e:any)=>{
                        console.log(e);                    
                });
            } catch (error) {
                console.log(error,'add business 406');
            }
              }).catch(()=>{
                alert('Something goes wrong!')
                setloading(false)
              });
        
        }else{
        setSiteMsg('Please Enter Required Fields*')    
        setloading(false)  
        }
    }

    
        const updatecheckInsOptions=async(v:any,name:any)=>{                        
            const val = v.target.value  
           switch (name) {
                case 'bannedCusotmerTableRowBgColor':
                    checkInsOptions.bannedCusotmerTableRowBgColor = val
                break; 
                case 'bannedCusotmerTableRowFontColor':
                    checkInsOptions.bannedCusotmerTableRowFontColor = val
                break; 
                case 'buttonColor':
                    checkInsOptions.buttonColor = val
                break; 
                case 'leftSideBgColor':
                    checkInsOptions.leftSideBgColor = val
                break; 
                case 'leftSideTagColor':
                    checkInsOptions.leftSideTagColor = val
                break; 
                case 'mainBackgroundColor':
                    checkInsOptions.mainBackgroundColor = val
                break;               
                case 'tableBackgroundColor':
                    checkInsOptions.tableBackgroundColor = val
                break; 
                case 'tableBorderColor':
                    checkInsOptions.tableBorderColor = val
                break; 
                case 'tableFontColor':
                    checkInsOptions.tableFontColor = val
                break; 
           }   
             
            setcheckInsOptions(checkInsOptions)
            checkInsOptionsSave()
          
        }

const checkInsOptionsSave=async()=>{
    updateQueryFirebaseBusienss({checkInsOptions})
}

const updateQueryFirebaseBusienss=async(data:any)=>{
    if(businessId!==undefined&&businessId!==null && businessId!==''){
    setDisabled(true);
    console.log(data);    
    await updateDoc(doc(firebase, "businesses", businessId), data).then(docRef => {
        console.log('checkInsOptions successfully updated '+businessId);
        setDisabled(false);
    }).catch((e)=>{console.log(e); })
    setDisabled(false);
}
 }


        // logo upload business//
    const FileUploader=(filex:any)=>{
        setDisabled(true);
        const storage = getStorage();
        // Create the file metadata
        /** @type {any} */
        const metadata = {
          contentType: 'image/jpeg'
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        const file =filex[0];
        const storageRef = ref(storage, 'uploaded-logos/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
          (snapshot:any) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          }, 
          (error:any) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;        
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          }, 
          () => {
            const theme:any ={}
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {

                theme.logo =downloadURL               
                theme.background ='linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8))'  
                theme.colors ={
                    error:"#e23229",
                    primary:"#2b3c8b",
                    secondary:"#fcad14",
                    success:"#467f1e",
                    warning:"#e8a530"
                   }  
                 settheme(theme)

                
                await updateDoc(doc(firebase, "businesses", businessId), {theme}).then(docRef => {
                    console.log(docRef);
                    
                    setDisabled(false);
                    console.log('File available at', downloadURL);
                }).catch((e)=>{
                    setDisabled(false)
                    console.log(e);
                    
                })
                                

            });
          }
        );

    }


    const handleInventoryNotification=(e:any)=>{
        setinventoryNotification(e.target.checked)           
        updateQueryFirebaseBusienss({inventoryNotification:e.target.checked})
        } 
        const handleinventoryEmailControl=(e:any)=>{
            setinventoryEmailControl(e.target.checked) 
            updateQueryFirebaseBusienss({inventoryEmailControl:e.target.checked})
        }   
     const handleyardsmartfeedControl=(e:any)=>{
            setyardsmartfeed(e.target.checked) 
            updateQueryFirebaseBusienss({yardsmartfeed:e.target.checked})
        }

        
    const handlemultilocationsmsnotification=(e:any)=>{
            setmultilocationsmsnotification(e.target.checked) 
            updateQueryFirebaseBusienss({multilocationsmsnotification:e.target.checked})
        }
        
    const handlesetlangEnglish=(e:any)=>{
        setlangEnglish(e.target.checked)
        updateQueryFirebaseBusienss({langEnglish:e.target.checked})
        }

    const handleemailinreport=(e:any)=>{
            setemailinreport(e.target.checked)
            updateQueryFirebaseBusienss({emailinreport:e.target.checked})
    }

        
    const handlesetlangSpanish=(e:any)=>{
        setlangSpanish(e.target.checked)
        updateQueryFirebaseBusienss({langSpanish:e.target.checked}) 
        }
    const handlesetproMembership=(e:any)=>{
        setproMembership(e.target.checked)
        updateQueryFirebaseBusienss({proMembership:e.target.checked})  
        }
    const handlesetproMembershipFreeEntry=(e:any)=>{
        setproMembershipFreeEntry(e.target.checked) 
        updateQueryFirebaseBusienss({proMembershipFreeEntry:e.target.checked})  
        }
    const handlesetsensitiveDataMaskingSwitch=(e:any)=>{
        setsensitiveDataMaskingSwitch(e.target.checked)
        updateQueryFirebaseBusienss({sensitiveDataMaskingSwitch:e.target.checked})  
        }
    const handlesetmodelYearRangeSwitch=(e:any)=>{
        setmodelYearRangeSwitch(e.target.checked)
        updateQueryFirebaseBusienss({modelYearRangeSwitch:e.target.checked})      
        }
    const handletodaysCouponOptionChange=(e:any)=>{
         settodaysCouponOption(e.target.checked)
         updateQueryFirebaseBusienss({todaysCouponOption:e.target.checked})      
    }

    const handletodaystodaysallTabOptionChange=(e:any)=>{
         settodaysallTabOption(e.target.checked)
         updateQueryFirebaseBusienss({todaysallTabOption:e.target.checked})      
    }

    const handlesetdarkmode=(e:any)=>{
        setdarkmode(e.target.checked)
        updateQueryFirebaseBusienss({darkmode:e.target.checked})       
        }
    const handlesetCheckedIdOption=(e:any)=>{
        setCheckedIdOption(e.target.checked)
        updateQueryFirebaseBusienss({CheckedIdOption:e.target.checked})      
        }
    const handlesetbannedCustomerOption=(e:any)=>{
        setbannedCustomerOption(e.target.checked)
        updateQueryFirebaseBusienss({bannedCustomerOption:e.target.checked})      
        }
    const handlesetbannedWarningAlert=(e:any)=>{
        setbannedWarningAlert(e.target.checked)
        updateQueryFirebaseBusienss({bannedWarningAlert:e.target.checked})      
        }
    const handlesetcolormode=(e:any)=>{
        setcolormode(e.target.checked)
        updateQueryFirebaseBusienss({colormode:e.target.checked}) 
        }
    const handlesetmarkForTheftSwitch=(e:any)=>{
        setmarkForTheftSwitch(e.target.checked)
        updateQueryFirebaseBusienss({markForTheftSwitch:e.target.checked}) 
        }
    const handlesetmarkForSellingCarSwitch=(e:any)=>{
         setmarkForSellingCarSwitch(e.target.checked) 
         updateQueryFirebaseBusienss({markForSellingCarSwitch:e.target.checked}) 
     }


    const handlesetmilitaryFirstResopnderSwitch=(e:any)=>{
        setmilitaryFirstResopnderSwitch(e.target.checked) 
        updateQueryFirebaseBusienss({militaryFirstResopnderSwitch:e.target.checked}) 
        }
    
    const handlesetmilitaryFirstResopnderSwitchFreeEntry=(e:any)=>{
        setmilitaryFirstResopnderSwitchFreeEntry(e.target.checked) 
        updateQueryFirebaseBusienss({militaryFirstResopnderSwitchFreeEntry:e.target.checked}) 
        }

    const handlesetaddaNewPickSwitch=(e:any)=>{
        setaddaNewPickSwitch(e.target.checked) 
        updateQueryFirebaseBusienss({addaNewPickSwitch:e.target.checked})      
        }

        const handlesetshowPickLeft=(e:any)=>{
            setshowPickLeft(e.target.checked) 
            updateQueryFirebaseBusienss({showPickLeft:e.target.checked})    
            }
  
        const handlesetfeedType=(v:any)=>{
                setfeedType(v) 
                updateQueryFirebaseBusienss({feedType:v})    
            }
      
        const handlesetFeedAlertDays=(v:any)=>{
            setFeedAlertDays(v) 
               updateQueryFirebaseBusienss({FeedAlertDays:v})    
            }
              
  
            
        const addRemoveSiteFromSmsGroup=(e:any)=>{
                let smsgr:any=[];
                if(e.target.checked){
                const arr:any=  smsgroup;
                arr.push(e.target.value);
                setsmsgroup(arr)
                smsgr=arr;
                }else{
                  const arr:any=  smsgroup;
                  const myArray = arr.filter((item:any)=> {
                    return item !== e.target.value;
                });
                  setsmsgroup(myArray)
                  smsgr=myArray;
                }
                updateQueryFirebaseBusienss({smsgroup:smsgr})
            }
                                                                                                                                                                                                                                

return(
        <div className="container-fluid">
          
            <div >
            <H1>{businessName}</H1>
            </div>
            <div className="form-field" >
                {currentBusiness.isArchive===undefined || currentBusiness.isArchive===0 ? 
         <Button intent="danger" onClick={()=>updateQueryFirebaseBusienss({isArchive:1})}>Archive</Button>:
         <Button intent="success" onClick={()=>updateQueryFirebaseBusienss({isArchive:0})}>Restore</Button>
         }</div>

        <div className="row-full-width">
            <div  className="wi-50" >
            <div className="form-field">
                        <H3>Business Logo: <HelpElement text={'Business Logo'}/></H3>
                      {themee && themee.logo && !Updatelogo ? 
                      <Button icon='media' intent="primary" onClick={()=>setUpdatelogo(true)}>Update logo</Button> 
                      : <InputGroup
                        className="filepaddingleft"
                        type="file"
                            disabled={false}
                            large={false}
                            placeholder="Check-ins minimum delay"
                            readOnly={false}
                            rightElement={<Button small={true} intent="primary">change</Button>}
                            onChange={(f:any)=>FileUploader(f.target.files) }
                        /> } 
                    </div>
            </div>
            <div className="wi-50">
            {themee && themee.logo ? <img className="logo" src={themee && themee.logo ? themee.logo : ''}/>:''}
            </div>
        </div>

        <div className="row-full-width">
    
            <div className="">
            <div className="form-field" style={{display:'flex'}}>
                    <H3>kiosks : <HelpElement text={'kiosks'}/></H3>                         
            </div> 
                <div className="form-field">
                <div><Button fill={false} onClick={()=>setaddnewkiosk(true)}>add new kiosk</Button></div>      <br/> 
                {/* <H3>kiosk Links: <HelpElement text={'kiosks'}/></H3> */}
            {sites.map((x:any)=>(  <p ><CopyLink url={urls.baseUrlKiosk+'/'+path+'/'+x.path} /></p>))}
                </div> 
            </div>
        </div>

        <div className="row-full-width">
         <div className="wi-50">
            <div className="form-field">
                <H3>Ivison Coupon Api Token:  <HelpElement text={'Ivision V2 coupon Api need business token to fetch/update coupon information.'}/></H3>
                <InputGroup
                    disabled={false}
                    large={false}
                    placeholder="Enter Ivison Coupon V2 Api Token"
                    readOnly={false}
                    rightElement={<Button small={true} disabled={Disabled} intent="primary"  onClick={()=>updateQueryFirebaseBusienss({businesstoken})}>change</Button>}
                    value={businesstoken || ""}
                    onChange={(e:any)=>setbusinesstoken(e.target.value)}
                />
            </div>
         </div>
         <div className="wi-50">
         {feedType==='s3id'?    <div className="form-field">
                    <H3>Crush Vehicle Feed #: <HelpElement text={'s3Id'}/></H3>
                    <InputGroup
                        disabled={false}
                        large={false}
                        placeholder="Enter s3Id"
                        readOnly={false}
                        rightElement={<Button small={true} disabled={Disabled} intent="primary"  onClick={()=>updateQueryFirebaseBusienss({s3Id})}>change</Button>}
                        value={s3Id}
                        onChange={(e:any)=>sets3Id(e.target.value)}
                    />
            </div> :
           ''
            }


         </div>         
        </div>
<div className="row-full-width">
    <div className="wi-50">
    <div className="form-field">
                        <H5>ivision Message code: </H5>
                        <InputGroup
                            onChange={(e:any)=>setshortcode(e.target.value)} 
                            disabled={Disabled}
                            large={false}
                            placeholder="Enter ivision code"
                            readOnly={false}
                            value={shortcode || ""}
                            rightElement={<Button small={true} intent="primary"
                            onClick={()=>updateQueryFirebaseBusienss({shortcode})}
                            >change</Button>}
                        />
                </div>
    </div>
    <div className="wi-50">
        <div className="form-field">
                            <H5>Business iVision GUID:</H5>  
                            <div className="bp5-input-group">
                                <InputMask className="bp5-input font-20" mask="______________________________________" replacement={"_"} value={guid ? guid: ''} showMask separate  disabled={Disabled}
                                onChange={(e:any)=>setguid(e.target.value)} 
                                />   
                                <span className="bp5-input-action">
                                    <button type="button" className="bp5-button bp5-small bp5-intent-primary"
                                    onClick={()=>updateQueryFirebaseBusienss({guid})}
                                    >
                                        <span className="bp5-button-text">change</span>
                                    </button>
                                </span>
                            </div>                     
                    </div>
        </div>
</div>

     <div className="row-full-width">
        {justInKeyword!==undefined && justInKeyword!=='' ?   <div className="wi-50">
           <div className="form-field">
                <H3>Just In Keyword Campaign Name: <HelpElement text={'ijoi'}/></H3>
                <InputGroup
                    disabled={false}
                    large={false}                                     
                    placeholder="Enter Just-In keyword"
                    readOnly={false}
                    rightElement={<Button small={true} disabled={Disabled} onClick={()=>updateQueryFirebaseBusienss({justInKeyword})} intent="primary">change</Button>}
                    value={justInKeyword}
                    onChange={(e:any)=>setjustInKeyword(e.target.value)}                    
                />
            </div>
         </div>:''}
                 {feedType!=='s3id'? <div className="wi-50">
          <div className="form-field">
                        <H3>URG Vehicle Feed #: <HelpElement text={'urgId'}/></H3>
                        <InputGroup
                            disabled={false}
                            large={false}
                            placeholder="Enter urgId"
                            readOnly={false}
                            rightElement={<Button small={true} intent="primary" disabled={Disabled} onClick={()=>updateQueryFirebaseBusienss({urgId})}>change</Button>}
                            value={urgId}
                            onChange={(e:any)=>seturgId(e.target.value)}
                        />
            </div>
         </div>:''}


              {/* {feedType!=='s3id'? <div className="wi-50">
          <div className="form-field">
                        <H3>URG Vehicle Feed #: <HelpElement text={'urgId'}/></H3>
                        <InputGroup
                            disabled={false}
                            large={false}
                            placeholder="Enter urgId"
                            readOnly={false}
                            rightElement={<Button small={true} intent="primary" disabled={Disabled} onClick={()=>updateQueryFirebaseBusienss({urgId})}>change</Button>}
                            value={urgId}
                            onChange={(e:any)=>seturgId(e.target.value)}
                        />
            </div>
         </div>:''} */}

        </div>
            

            


            
            
            <div className="form-field" style={{marginLeft:'8px'}}>
                <H3 style={{marginLeft:'-8px'}}>Inventory Feed Alert: (If no feed in last selected days) <HelpElement text={'Inventory Feed Alert: (If no feed in last selected days)'}/></H3>
                <Slider
                    min={0}
                    max={91}
                    stepSize={1}
                    labelStepSize={10}
                    onChange={(w:any)=>handlesetFeedAlertDays(w)}
                    labelRenderer={true}
                    showTrackFill={false}
                    value={FeedAlertDays ?  parseInt(FeedAlertDays+'',10) : 0}
                    vertical={false}
                    handleHtmlProps={{ "aria-label": "example 3" }}
                />
            </div>

 <div className="row-full-width">
    <div className="wi-50">
                    <div className="form-field">
                        <H3>Yard Management System (YMS): <HelpElement text={'Yard Management System (YMS)'}/></H3>
                            <label className="feedtypecss"><input type="radio" name="feedtype" value='s3id'  onChange={(e)=>e.target.checked? handlesetfeedType('s3id'):console.log('unchecked')}  checked={feedType==='s3id'}/>&nbsp;S3(Crush)</label>
                            <details>
                            <summary className="feedtypecss" >URG</summary>
                            <label className="feedtypecss"><input type="radio" name="feedtype" value='hollander'  onChange={(e)=>e.target.checked? handlesetfeedType('hollander'):console.log('unchecked')} checked={feedType==='hollander'}/>&nbsp;Hollander</label><br/>
                            <label className="feedtypecss"><input type="radio" name="feedtype" value='checkmate'  onChange={(e)=>e.target.checked? handlesetfeedType('checkmate'):console.log('unchecked')}  checked={feedType==='checkmate'}/>&nbsp;Checkmate</label><br/>
                            <label className="feedtypecss"><input type="radio" name="feedtype" value='pannacle' onChange={(e)=>e.target.checked? handlesetfeedType('pannacle'):console.log('unchecked')} checked={feedType==='pannacle'}/>&nbsp;Pinnacle</label><br/>
                            <label className="feedtypecss"><input type="radio" name="feedtype" value='proprietary' onChange={(e)=>e.target.checked? handlesetfeedType('proprietary'):console.log('unchecked')} checked={feedType==='proprietary'}/>&nbsp;Proprietary (inhouse)</label><br/>
                            </details>
                    </div>

        <div className="form-field">
                <H3>Email In Report: <HelpElement text={'Email In Report'}/></H3>
                <Switch  checked={emailinreport} onChange={(e:any)=>handleemailinreport(e)} innerLabelChecked="on" innerLabel="off" />
        </div>  
    </div>
    <div className="wi-50">

    <div className="form-field">
                <H3>Business Language: <HelpElement text={'Business Language'}/></H3>
                <Switch label="English" checked={langEnglish} onChange={(e:any)=>handlesetlangEnglish(e)} innerLabelChecked="on" innerLabel="off" />
                <Switch label="Spanish" checked={langSpanish} onChange={(e:any)=>handlesetlangSpanish(e)} innerLabelChecked="on" innerLabel="off" />
    </div>  

        </div>
</div>

<div className="form-field" >
                    <H3>Multi location sms notification: <HelpElement text={'Milti location switch'}/></H3>
                    <Switch onChange={(e:any)=>handlemultilocationsmsnotification(e)} checked={multilocationsmsnotification} innerLabelChecked="on" innerLabel="off" />
    </div>
    
  {multilocationsmsnotification?  <div className="form-field" style={{display:"flex"}}>
        {sites.map((x:any)=>(  
        <H6 >&nbsp;<Label><input type="checkbox" onChange={(e)=>addRemoveSiteFromSmsGroup(e)} checked={smsgroup.includes(x.siteId)} value={x.siteId}/>&nbsp;{x.path}&nbsp;&nbsp;</Label> </H6>
    ))}
    </div>:''}

                <div className="form-field" >
                    <H3>Inventory Emails: <HelpElement text={'Inventory Emails'}/></H3>
                    <Switch onChange={(e:any)=>handleinventoryEmailControl(e)} checked={inventoryEmailControl} innerLabelChecked="on" innerLabel="off" />
                </div>
                {/* <div className="form-field" >
                    <H3>Yard smart Feed: <HelpElement text={'Yard smart Feed (RTP)'}/></H3>
                    <Switch onChange={(e:any)=>handleyardsmartfeedControl(e)} checked={yardsmartfeed} innerLabelChecked="on" innerLabel="off" />
                </div>
                    {yardsmartfeed?
                     <div className="row-full-width">                    
                        <div className="wi-50">
                        <InputGroup
                        disabled={false}
                        large={false}
                        placeholder="Enter yardsmart id"
                        readOnly={false}
                        rightElement={<Button small={true} disabled={Disabled} onClick={()=>updateQueryFirebaseBusienss({yardsmartid})} intent="primary">change yardsmart id</Button>}
                        onChange={(e)=>setyardsmartid(e.target.value)}
                        value={yardsmartid}
                    />
                        </div>

                    </div>
                    :''}  */}

                {inventoryEmailControl ? <div>
                    <span className="d-flex form-field"><H3>Daily Notification For Inventory: <HelpElement text={'Inventory Emails'}/></H3>&nbsp;
                    <Switch onChange={(e:any)=>handleInventoryNotification(e)} checked={inventoryNotification} innerLabelChecked="All" innerLabel="Only&nbsp;Available" /></span>
                    <TagInput
                        leftIcon={"inbox"}
                        onChange={(e)=>setinventoryNotificationEmails(e)}
                        placeholder="Enter emails"
                        values={inventoryNotificationEmails}
                    />
                    <div className="form-field">
                    <H3>Stock Image Url (Stock number replace with "xxxxx"): <HelpElement text={'s3Id'}/></H3>
                    <InputGroup
                        disabled={false}
                        large={false}
                        placeholder="Enter Stock Image Url"
                        readOnly={false}
                        rightElement={<Button small={true} disabled={Disabled} onClick={()=>updateQueryFirebaseBusienss({StockImageUrl})} intent="primary">change</Button>}
                        onChange={(e)=>setStockImageUrl(e.target.value)}
                        value={StockImageUrl}
                    />
                    </div>
                    <div className="form-field">
                        <H3>Stock Inventory Location Rules: <HelpElement text={'s3Id'}/></H3>
                        <InputGroup
                            disabled={false}
                            large={false}
                            placeholder="Enter Stock Inventory Location Rules"
                            readOnly={false}
                            rightElement={<Button small={true} disabled={Disabled} onClick={()=>updateQueryFirebaseBusienss({stockInventoryRules})}  intent="primary">change</Button>}
                            onChange={(e)=>setstockInventoryRules(e.target.value)}
                            value={stockInventoryRules}
                        />
                    </div>
                </div>:''}
           
           

           
            {(justInKeyword!==undefined && justInKeyword!=='') && ((s3Id!==null && s3Id!=='') || (urgId!==null && urgId!=='') || multilocationsmsnotification) ?
            <div className="row-full-width">
                <div className="wi-50">
                    <H3>Just In QR Code: <HelpElement text={'Bussiness Language'}/></H3>
                    <QRCode value={multilocationsmsnotification ? urls.baseUrlJustIn+'/'+path : urls.baseUrlJustIn+'/'+path+'/-/'} renderAs="canvas" />  
                </div>
                <div className="wi-50" >          
                    <H3>Just in landing page link: <HelpElement text={'Just in landing page link'}/></H3>
                    <br/><br/>
                    <CopyLink  url={multilocationsmsnotification ? urls.baseUrlJustIn+'/'+path : urls.baseUrlJustIn+'/'+path+'/-/'} />
                </div>
            </div>:''}
            {/* <div className="form-field">
                <H3>Pro Member feature : <HelpElement text={'Pro Member feature'}/></H3>
                <em>Will turn on the "Pro membership" button for cashiers and enable the feature.</em>
                <div className="mt-10"><Switch checked={proMembership} onChange={(e:any)=>handlesetproMembership(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div> */}
            {/* {proMembership!==undefined && proMembership ? <div className="form-field">
                <H5>Free Entry : <HelpElement text={'militaryFirstResopnderSwitch'}/></H5>
                <div><Switch  checked={proMembershipFreeEntry} onChange={(e:any)=>handlesetproMembershipFreeEntry(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>:''} */}

            <div className="row-full-width">
          <div className="wi-50">
                        {/*   <div className="form-field">
                        <H3>Sensitive Data Masking Switch : <HelpElement text={'Sensitive Data Masking Switch'}/></H3>
                        <div><Switch  checked={sensitiveDataMaskingSwitch} onChange={(e:any)=>handlesetsensitiveDataMaskingSwitch(e)} innerLabelChecked="on" innerLabel="off" /></div>
                    </div>
                    <div className="form-field">
                        <H3>Just-In page Model Year Range Switch : <HelpElement text={'Just-In page Model Year Range Switch'}/></H3>
                        <div><Switch  checked={modelYearRangeSwitch} onChange={(e:any)=>e.target.checked ? setmodelYearRangeSwitch(true) : setmodelYearRangeSwitch(false)} innerLabelChecked="on" innerLabel="off" /></div>
                    </div>*/}

                      <div className="form-field">
                        <H3>Todays Check-In Coupon Option: <HelpElement text={Tooltips['todaysCouponOption']+''}/></H3>
                        <div><Switch 
                        onChange={(e)=>handletodaysCouponOptionChange(e)}
                        checked={todaysCouponOption !==undefined && todaysCouponOption !== null ? todaysCouponOption : false}
                        innerLabelChecked="on" innerLabel="off" /></div>
                    </div>
                </div> 
                <div className="wi-50">
                  
                    <div className="form-field">
                        <H3>Todays Check-In All Tab Option : <HelpElement text={Tooltips['todaysallTabOption']+''}/></H3>
                        <div><Switch 
                        onChange={(e)=>handletodaystodaysallTabOptionChange(e)}
                        checked={todaysallTabOption !==undefined && todaysallTabOption !== null ? todaysallTabOption : false}
                        innerLabelChecked="on" innerLabel="off" /></div>
                    </div>
                </div>
            </div>

            {/* <div className="form-field" style={{marginLeft:'8px'}}>
                        <H3>Display The Row in Just In Text Message Enable : <HelpElement text={'Display The Row in Just In Text Message Enable'}/></H3>
                        <div><Switch  checked={enableRowInTxtMsgSwitch} onChange={(e:any)=>handlesetmodelYearRangeSwitch(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div> */}


        <H1>Check in UI</H1>
          <div className="row-full-width">
          <div className="w-50">
            <div className="form-field">
                <H3>Dark Mode : <HelpElement text={'Dark Mode'}/></H3>
                <div><Switch  checked={darkmode} onChange={(e:any)=>handlesetdarkmode(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
            <div className="form-field">
                <H3>Checked ID Option : <HelpElement text={'Checked ID Option'}/></H3>
                <div><Switch  checked={CheckedIdOption} onChange={(e:any)=>handlesetCheckedIdOption(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
            <div className="form-field">
                <H3>Banned Customer Option : <HelpElement text={'Banned Customer Option'}/></H3>
                <div><Switch  checked={bannedCustomerOption} onChange={(e:any)=>handlesetbannedCustomerOption(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
            <div className="form-field">
                <H3>Banned Warning Alert : <HelpElement text={'Banned Warning Alert'}/></H3>
                <div><Switch  checked={bannedWarningAlert} onChange={(e:any)=>handlesetbannedWarningAlert(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
            <div className="form-field">
                <H3>Color Mode : <HelpElement text={'Color Mode'}/></H3>
                <div><Switch  checked={colormode} onChange={(e:any)=>handlesetcolormode(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
          </div>
          <div className="w-50">
            <div className="form-field">
                <H3>Mark For Theft Switch : <HelpElement text={'Mark For Theft Switch'}/></H3>
                <div><Switch  checked={markForTheftSwitch} onChange={(e:any)=>handlesetmarkForTheftSwitch(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
            <div className="form-field">
                <H3>Ca$h For Car Switch : <HelpElement text={'Ca$h For Car Switch'}/></H3>
                <div><Switch  checked={markForSellingCarSwitch} onChange={(e:any)=>handlesetmarkForSellingCarSwitch(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
            <div className="form-field">
                <H3>Military / First Responder Switch : <HelpElement text={'Military / First Responder Switch'}/></H3>
                <div><Switch  checked={militaryFirstResopnderSwitch} onChange={(e:any)=>handlesetmilitaryFirstResopnderSwitch(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>
            {militaryFirstResopnderSwitch!==undefined && militaryFirstResopnderSwitch ? <div className="form-field">
                <H5>Free Entry : <HelpElement text={'militaryFirstResopnderSwitch'}/></H5>
                <div><Switch  checked={militaryFirstResopnderSwitchFreeEntry} onChange={(e)=>handlesetmilitaryFirstResopnderSwitchFreeEntry(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>:''}

            <div className="form-field">
                <H3>Add a New Pick Switch : <HelpElement text={'Add a New Pick Switch'}/></H3>
                <div><Switch  checked={addaNewPickSwitch} onChange={(e:any)=>handlesetaddaNewPickSwitch(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>          
          </div>

          </div>


            
          
            
         {colormode?
         
        <span> 
        <div className="row-full-width">
           <div className="w-33">
            <div className="form-field w-20">
                <H3>Main Background Color: <HelpElement text={'Main Background Color'}/></H3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <InputGroup
                    type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Main Background Color"
                    readOnly={false}
                    value={checkInsOptions ? checkInsOptions.mainBackgroundColor : '#fff' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'mainBackgroundColor')}                    
                    rightElement={ <InputGroup className="rightInput" type="text"  onChange={(e:any)=>updatecheckInsOptions(e,'mainBackgroundColor')}  value={checkInsOptions ? checkInsOptions.mainBackgroundColor : '#fff' }/>}
                    />               
           </div>
        </div>
        <div className="w-33">
            <div className="form-field w-20">
                <H3>Banned customer table row background Color : <HelpElement text={'Banned customer table row background Color'}/></H3>
                <InputGroup                
                   type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Banned customer table row background Color"
                    readOnly={false}
                    value={checkInsOptions? checkInsOptions.bannedCusotmerTableRowBgColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'bannedCusotmerTableRowBgColor')}
                    rightElement={ <InputGroup className="rightInput" type="text"  onChange={(e:any)=>updatecheckInsOptions(e,'bannedCusotmerTableRowBgColor')}  value={checkInsOptions? checkInsOptions.bannedCusotmerTableRowBgColor : '' }/>}
                />
            </div>
        </div>
        <div className="w-33">
            <div className="form-field w-20">
                <H3>Banned customer table row Font Color : <HelpElement text={'Banned customer table row Font Color'}/></H3>
                <InputGroup
                   type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Enter Banned customer table row Font Color"
                    readOnly={false}                   
                    value={checkInsOptions  ? checkInsOptions.bannedCusotmerTableRowFontColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'bannedCusotmerTableRowFontColor')}
                    rightElement={ <InputGroup className="rightInput" type="text"  onChange={(e:any)=>updatecheckInsOptions(e,'bannedCusotmerTableRowFontColor')}  value={checkInsOptions  ? checkInsOptions.bannedCusotmerTableRowFontColor : '' }/>}
                />
            </div>
        </div>
    </div>

    <div className="row-full-width">
           <div className="w-33">
            <div className="form-field w-20">
                <H3>Table Background Color : <HelpElement text={'Table Background Color'}/></H3>
                <InputGroup
                   type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Table Background Color"
                    readOnly={false}
                    value={checkInsOptions  ? checkInsOptions.tableBackgroundColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'tableBackgroundColor')}
                    rightElement={ <InputGroup className="rightInput" type="text"  onChange={(e:any)=>updatecheckInsOptions(e,'tableBackgroundColor')}  value={checkInsOptions  ? checkInsOptions.tableBackgroundColor : '' }/>}

                />
            </div>
            </div>
            <div className="w-33">
            <div className="form-field w-20">
                <H3>Table Border Color : <HelpElement text={'Table Border Color'}/></H3>
                <InputGroup
                   type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Table Border Color"
                    readOnly={false}
                    value={checkInsOptions ? checkInsOptions.tableBorderColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'tableBorderColor')}
                    rightElement={ <InputGroup className="rightInput" style={{width:'20%!important'}} type="text" onChange={(e:any)=>updatecheckInsOptions(e,'tableBorderColor')} value={checkInsOptions ? checkInsOptions.tableBorderColor : '' }/>}
                />
            </div>
            </div>
            <div className="w-33">
            <div className="form-field w-20">
                <H3>Table Font Color : <HelpElement text={'Table Font Color'}/></H3>
                <InputGroup
                   type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Table Font Color"
                    readOnly={false}
                    rightElement={ <InputGroup className="rightInput" type="text" onChange={(e:any)=>updatecheckInsOptions(e,'tableFontColor')} value={checkInsOptions && checkInsOptions.tableFontColor ? checkInsOptions.tableFontColor : '' }/>}
                    value={checkInsOptions && checkInsOptions.tableFontColor ? checkInsOptions.tableFontColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'tableFontColor')}
                />
            </div>
        </div>
    </div>

    <div className="row-full-width">
        <div className="w-33">
            <div className="form-field w-20">
                <H3>Button Color: <HelpElement text={'Button Color'}/></H3>
                <InputGroup
                   type="color"
                    disabled={Disabled}
                    large={false}
                    placeholder="Button Color"
                    readOnly={false}
                    rightElement={ <InputGroup className="rightInput" type="text" onChange={(e:any)=>updatecheckInsOptions(e,'buttonColor')} value={checkInsOptions && checkInsOptions.buttonColor ? checkInsOptions.buttonColor : '' }/>}
                    value={checkInsOptions && checkInsOptions.buttonColor ? checkInsOptions.buttonColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'buttonColor')}
                />
            </div>
        </div>
        <div className="w-33">
            <div className="form-field w-20">
                <H3>Left Tag Background Color: <HelpElement text={'Left  Tag Background Color '}/></H3>
                <InputGroup
                   type="color"
                   disabled={Disabled}
                    large={false}
                    placeholder="Left Side Tag Background Color "
                    readOnly={false}
                    rightElement={ <InputGroup className="rightInput" type="text" onChange={(e:any)=>updatecheckInsOptions(e,'leftSideTagColor')} value={checkInsOptions && checkInsOptions.leftSideTagColor ? checkInsOptions.leftSideTagColor : '' }/>}
                    value={checkInsOptions && checkInsOptions.leftSideTagColor ? checkInsOptions.leftSideTagColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'leftSideTagColor')}
                />
            </div>
        </div>
         <div className="w-33">
            <div className="form-field w-20">
                <H3>Left Side Background Color: <HelpElement text={'Left Side Background Color'}/></H3>
                <InputGroup
                   type="color"
                   disabled={Disabled}
                    large={false}
                    placeholder="Left Side Background Color"
                    readOnly={false}
                    rightElement={ <InputGroup className="rightInput" type="text" onChange={(e:any)=>updatecheckInsOptions(e,'leftSideBgColor')} value={checkInsOptions && checkInsOptions.leftSideBgColor ? checkInsOptions.leftSideBgColor : '' }/>}
                    value={checkInsOptions && checkInsOptions.leftSideBgColor ? checkInsOptions.leftSideBgColor : '' }
                    onChange={(e:any)=>updatecheckInsOptions(e,'leftSideBgColor')}
                />
            </div>
        </div>
        </div>
</span>:''}

            <div className="form-field">
                <H3>Delete picks after 90 days feature : <HelpElement text={'Delete picks after 90 days feature'}/></H3>
                <div><Switch checked={showPickLeft ? showPickLeft : false} onChange={(e:any)=>handlesetshowPickLeft(e)} innerLabelChecked="on" innerLabel="off" /></div>
            </div>


           
              


              <Dialog
                title="Add New Kiosk"
                icon="info-sign"
                isOpen={addnewkiosk}
                onClose={()=>setaddnewkiosk(false)}>
                <div className={Classes.DIALOG_BODY}>
                <div className="form-field">
                        <H5>Kiosk Location*</H5>
                        <InputGroup
                        type="text"
                            disabled={false}
                            large={false}
                            placeholder="Enter kiosk Location"
                            onChange={(e:any)=>{setspath(convertToPath(e.target.value)); setsiteName(e.target.value)}}
                            readOnly={false}                    
                            value={siteName!==null ? siteName : ''}
                        />
                    </div>
                    <div className="form-field">
                        <H5>kiosk Name ( Ivision )* </H5>
                        <InputGroup
                        type="text"
                            disabled={false}
                            large={false}
                            placeholder="Enter kiosk name ( ivision )"
                            onChange={(e:any)=>setkioskName(e.target.value)}
                            readOnly={false}                    
                            value={kioskName!==null ? kioskName : ''}
                        />
                    </div>
                    <div className="form-field">
                        <H5>Spanish Kiosk Name ( Ivision )* </H5>
                        <InputGroup
                        type="text"
                            disabled={false}
                            large={false}
                            placeholder="Enter spanish kiosk name ( ivision )"
                            onChange={(e:any)=>setkioskNameEs(e.target.value)}
                            readOnly={false}                    
                            value={kioskNameEs!==null ? kioskNameEs : ''}
                        />
                    </div>

                    <div className="form-field">
                            <RadioGroup
                            label="Suppress Check-In"
                            onChange={(e:any)=>setnoCheckIn(e.target.defaultValue==='no' ? false : true)}                            
                            selectedValue={noCheckIn!==undefined && noCheckIn===true ? 'yes' : 'no'}
                            >
                            <Radio label="yes" value="yes" />
                            <Radio label="no" value="no" />
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                            <RadioGroup
                            label="Signature"
                            onChange={()=>console.log('okay')}                            
                            >
                            {/* <Checkbox label="always display"  value={'always'} onChange={(e:any)=>Handlesetroutes('signature','always',e.target.checked)} checked={routes.signature.alwaysShow}/> */}
                            <Checkbox label="enabled"  value={'enabled'} onChange={(e:any)=>Handlesetroutes('signature','enabled',e.target.checked)} checked={routes.signature.enabled} />
                            {/* <Checkbox label="optional"  value={'optional'} onChange={(e:any)=>Handlesetroutes('signature','optional',e.target.checked)} checked={routes.signature.optional}/> */}
                            <Checkbox label="take picture" value={'takeMugshot'} onChange={(e:any)=>Handlesetroutes('signature','takeMugshot',e.target.checked)} checked={routes.signature.takeMugshot} />
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                        <H5>Waiver text </H5>
                        <textarea                        
                            disabled={false}
                            rows={5}    
                            cols={50}                       
                            placeholder="Enter waiver text"
                            readOnly={false}                    
                            onChange={(e:any)=>Handlesetroutes('signature','waiverText',e.target.value)} 
                        >{routes.signature.waiverText}
                        </textarea>
                    </div>
                    <div className="form-field">
                        <H5>waiver text (spanish) (optional) </H5>
                        <textarea                        
                            disabled={false}
                            rows={5}     
                            cols={50}                      
                            placeholder="Enter waiver text (spanish) "
                            readOnly={false}                    
                            onChange={(e:any)=>Handlesetroutes('signature','waiverTextEs',e.target.value)} 
                            >{routes.signature.waiverTextEs}
                        </textarea>
                    </div>
                    <div className="form-field">
                            <RadioGroup
                            label="Date Of Birth"
                            onChange={()=>console.log('okay')}
                            selectedValue={''}
                            >
                            <Checkbox label="always display"  onChange={(e:any)=>Handlesetroutes('dateofbirth','always',e.target.checked)} checked={routes.dateofbirth.alwaysShow}/>
                            <Checkbox label="enabled"  onChange={(e:any)=>Handlesetroutes('dateofbirth','enabled',e.target.checked)} checked={routes.dateofbirth.enabled}/>
                            <Checkbox label="optional"  onChange={(e:any)=>Handlesetroutes('dateofbirth','optional',e.target.checked)} checked={routes.dateofbirth.optional}/>
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                            <RadioGroup
                            label="Email"
                            onChange={()=>console.log('okay')}
                            selectedValue={''}
                            >
                            <Checkbox label="always display"  onChange={(e:any)=>Handlesetroutes('email','always',e.target.checked)} checked={routes.email.alwaysShow}/>
                            <Checkbox label="enabled"  onChange={(e:any)=>Handlesetroutes('email','enabled',e.target.checked)} checked={routes.email.enabled}/>
                            <Checkbox label="optional"  onChange={(e:any)=>Handlesetroutes('email','optional',e.target.checked)} checked={routes.email.optional}/>
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                            <RadioGroup
                            label="Language"
                            onChange={()=>console.log('okay')}
                            selectedValue={''}
                            >
                            {/* <Checkbox label="always display"  onChange={(e:any)=>Handlesetroutes('language','always',e.target.checked)} checked={routes.language.alwaysShow}/> */}
                            <Checkbox label="enabled"  onChange={(e:any)=>Handlesetroutes('language','enabled',e.target.checked)} checked={routes.language.enabled}/>
                            {/* <Checkbox label="optional"  onChange={(e:any)=>Handlesetroutes('language','optional',e.target.checked)} checked={routes.language.optional}/> */}
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                            <RadioGroup
                            label="Name"
                            onChange={()=>console.log('okay')}
                            selectedValue={''}
                            >
                            <Checkbox label="always display"  onChange={(e:any)=>Handlesetroutes('name','always',e.target.checked)} checked={routes.name.alwaysShow}/>
                            <Checkbox label="enabled"  onChange={(e:any)=>Handlesetroutes('name','enabled',e.target.checked)} checked={routes.name.enabled}/>
                            <Checkbox label="optional"  onChange={(e:any)=>Handlesetroutes('name','optional',e.target.checked)} checked={routes.name.optional}/>
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                            <RadioGroup
                            label="Vehicles"
                            onChange={()=>console.log('okay')}
                            selectedValue={''}
                            >
                            <Checkbox label="always display"  onChange={(e:any)=>Handlesetroutes('vehicles','always',e.target.checked)} checked={routes.vehicles.alwaysShow}/>
                            <Checkbox label="enabled"  onChange={(e:any)=>Handlesetroutes('vehicles','enabled',e.target.checked)} checked={routes.vehicles.enabled}/>
                            <Checkbox label="optional"  onChange={(e:any)=>Handlesetroutes('vehicles','optional',e.target.checked)} checked={routes.vehicles.optional}/>
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                            <RadioGroup
                            label="Zip"
                            onChange={()=>console.log('okay')}
                            selectedValue={''}
                            >
                            <Checkbox label="always display"  onChange={(e:any)=>Handlesetroutes('zip','always',e.target.checked)} checked={routes.zip.alwaysShow}/>
                            <Checkbox label="enabled"  onChange={(e:any)=>Handlesetroutes('zip','enabled',e.target.checked)} checked={routes.zip.enabled}/>
                            <Checkbox label="optional"  onChange={(e:any)=>Handlesetroutes('zip','optional',e.target.checked)} checked={routes.zip.optional}/>
                            </RadioGroup>
                    </div>
                    <div className="form-field">
                        <Button intent="success" loading={loading} disabled={loading} onClick={()=>addNewSite()}>Add Site</Button>
                    </div>
                    <div>
                        <p style={{color:'red'}}>{SiteMsg}</p>
                    </div>
                    <div><CopyLink url={urls.baseUrlKiosk+'/'+path+'/'+spath} /></div>   

                </div>
              </Dialog>
        </div>
    )
}