import React, { useEffect, useState } from "react";
import "../assets/groups.css"; // Add CSS for attractive design
import { Button, Checkbox, H3 } from "@blueprintjs/core";
import { addDoc, collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";
import { firebase } from "../global/firebase";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

interface Group {
  id: any;
  name: string;
  key: string;
  secret: string;
  plan: string;
  business:boolean,
  site:boolean,
  date:boolean,
  tf:boolean,
  totalcheckins:boolean,
  totalmsg:boolean,
  new:boolean,
  returning:boolean,
  optout:boolean,
  void:boolean,
  recheck:boolean,
  firstresponder:boolean,
  theft:boolean,
  totalmmsandsms:boolean,

  status: "active" | "inactive";
}

const BusinessGroups: React.FC = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupsf, setGroupsf] = useState<Group[]>([]);
  const [formData, setFormData] = useState<Partial<any>>({
    id: undefined,
    name: "",
    key: "",
    secret: "",
    plan: "",
    status: "active",
    business:true,
    site:true,
    date:true,
    tf:true,
    totalcheckins:true,
    totalmsg:true,
    new:true,
    returning:true,
    optout:true,
    void:true,
    recheck:true,
    firstresponder:true,
    theft:true,
    totalmmsandsms:true,
  });
  const final:any = useSelector(state=>state);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

        useEffect(()=>{

if(groupsf.length<=0){
                 getDocs(collection(firebase, "BusinessGroups")).then((docs)=>{
              
               if(!docs.empty){   
                const arr :any=[]
                docs.docs.map((val:any)=>{
                  const v =val.data();
                     v.id =val.id
                    arr.push(v)   
                  
                })
                arr.sort((a:any,b:any) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
                setGroups(arr);
                setGroupsf(arr);
               }

            })
          }

        },[groups,formData])


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const openModal = (group?: Group) => {
    if (group) {
           console.log(group);
           
      setFormData(group);
      setIsEditing(true);
    } else {
      resetForm();
      setIsEditing(false);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const handleAddGroup =async () => {
    const newGroup: Group = { ...formData, id: Date.now() } as Group;
    setGroups((prev) => [...prev, newGroup]);
    
    const str =newGroup.name.replace(/ /g, "-");
    formData.id = str
    console.log(formData);
    
          await setDoc(doc(collection(firebase, "BusinessGroups"),str), 
          formData
          ).catch((e)=>{
            closeModal();
            console.log(e);             
          }).then(async(i:any)=>{
            closeModal();  
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Successfully Added.",
              showConfirmButton: false,
              timer: 1500
            });          
                             
          }) 
  };

  const handleUpdateGroup = async() => {
    setGroups((prev) =>
      prev.map((group) => (group.id === formData.id ? { ...group, ...formData } : group))
    );

    const str =formData.id;
        if(str!==null){
          await setDoc(doc(collection(firebase, "BusinessGroups"),str), 
          formData
          ).catch((e)=>{
            closeModal();
            console.log(e);             
          }).then(async(i:any)=>{
            closeModal();  

            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Group has been saved",
              showConfirmButton: false,
              timer: 1500
            });
            // alert('Successfully Updated Group')
                             
          })
        }

  };

  const handleDeleteGroup =async (id: number,group:any) => {
   

    const str =group.id;
    if(str!==null){
     
      const sites =await getsites(str);
  
      Swal.fire({
        title: "Are you sure?",
        // text: "You won't be able to revert this!",
        html:sites==''? "<p style='text-align:center;'>You won't be able to revert this!</p>" : sites,
        icon: "warning",
        showConfirmButton:sites=='',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async(result) => {
        if (result.isConfirmed ) {
          setGroups((prev) => prev.filter((group) => group.id !== id));
      await deleteDoc(doc(firebase, "BusinessGroups",str)).catch((e)=>{
        closeModal();            
      }).then(async(i:any)=>{
        closeModal();  
        Swal.fire({
          position: "top-end",
          icon: 'info',
          title: "Successfully Deleted Group.",
          showConfirmButton: false,
          timer: 1500
        });
                         
      })

    }
  });

    }



  };

  const getsites=async(str:any)=>{
   let returnarr:any =''
   if(final.BusinessesReducer.Businesses[0]!==undefined && final.BusinessesReducer.Businesses!==null){

    final.BusinessesReducer.Businesses.map((b:any)=>{

      const sites =b.sites!==undefined ? b.sites :[];
 if(sites.length>0){
      sites.map((ss:any)=>{

        if('groupId' in ss && ss.groupId!==undefined && str==ss.groupId){

          returnarr+=`<p>Remove group from <b>${b.path}->${ss.path}</b> site form.</p>`
        }

      })
    }

    })

   }
   

   return returnarr

  }

  const resetForm = () => {
    setFormData({
      id: undefined,
      name: "",
      key: "",
      secret: "",
      plan: "",
      status: "active",
      business:true,
      site:true,
      date:true,
      tf:true,
      totalcheckins:true,
      totalmsg:true,
      new:true,
      returning:true,
      optout:true,
      void:true,
      recheck:true,
      firstresponder:true,
      theft:true,
      totalmmsandsms:true,
    });
  };

  const chagecolumn=(e:React.ChangeEvent<HTMLInputElement>)=>{
 

    setFormData((prev) => ({ ...prev, [e.target.value]: e.target.checked }));
  

  }

  let searchTimeout:any;
  const searchgroups=(s:any)=>{
   
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
    const result = groupsf.filter(v => ((v.name).toLowerCase()).includes((s.toLowerCase())));
          setGroups(result)

    });
  }

  return (
    <div className="group-crud-container">
        <br/>
      <h1>Group Management</h1>
      <div style={{textAlign:'left',marginBottom:'5px'}}> 
      <Button icon='plus'  intent="success" onClick={() => openModal()}>Add Group</Button>
      <input style={{float:'right'}} type="search" placeholder="search" onChange={(e)=>searchgroups(e.target.value)}/>
      </div>

      {/* Table */}
      <table className="group-table" style={{textAlign:'justify'}}>
        <thead>
          <tr>
            <th>Group Name</th>
            {/* <th>Key</th> */}
            {/* <th>Secret</th> */}
            {/* <th>Plan (Amount)</th> */}
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {groups.length<=0 ? (<tr> <td colSpan={5} style={{textAlign:'center'}}>Group not exist!</td></tr>)  :groups.map((group) => (
            <tr key={group.id}>
              <td>{group.name}</td>
              {/* <td>{group.key}</td> */}
              {/* <td>{group.secret}</td> */}
              {/* <td>{group.plan}</td> */}
              <td><span className={group.status=='inactive'? "badgei" :"badgea"}>{group.status}</span></td>
              <td>
                <Button intent="warning" onClick={() => openModal(group)}>Edit</Button>&nbsp;
                <Button  intent="danger" onClick={() => handleDeleteGroup(group.id,group)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>{isEditing ? "Edit Group" : "Add Group"}</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                isEditing ? handleUpdateGroup() : handleAddGroup();
              }}
            >
              <input
                type="text"
                name="name"
                // readOnly={isEditing}
                placeholder="Name"
                value={formData.name || ""}
                onChange={handleChange}
                required
              />

              <select name="status" value={formData.status || "active"} onChange={handleChange}>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>

              <input
              type="number"
              name="plan"
              placeholder="Plan (Number of SMS)"
              value={formData.plan || ""}
              onChange={handleChange}
              // required
            />

            <H3>Dashboard columns</H3>
<div style={{maxHeight:'200px',overflow:'auto',marginBottom:'10px'}}>
<Checkbox checked={ formData.business}  value={'business'} onChange={chagecolumn} label="Business" />
<Checkbox checked={formData.site }  value={'site'} onChange={chagecolumn} label="Site" />
<Checkbox checked={formData.date }  value={'date'} onChange={chagecolumn} label="Date" />
<Checkbox checked={formData.tf }  value={'tf'} onChange={chagecolumn} label="Message code" />
<Checkbox checked={formData.totalcheckins }  value={'totalcheckins'} onChange={chagecolumn} label="Total Checkins (new+returning+opt-out+void)" />
<Checkbox checked={formData.totalmsg }  value={'totalmsg'} onChange={chagecolumn} label="Total Message Credits Used (SMS & MMS)" />
<Checkbox checked={formData.new }  value={'new'} onChange={chagecolumn} label="New" />
<Checkbox checked={formData.returning }  value={'returning'} onChange={chagecolumn} label="Returning" />
<Checkbox checked={formData.optout }  value={'optout'} onChange={chagecolumn} label="Opt-Out" />
<Checkbox checked={formData.void }  value={'void'} onChange={chagecolumn} label="Void" />
<Checkbox checked={formData.recheck }  value={'recheck'} onChange={chagecolumn} label="Recheck" />
<Checkbox checked={formData.firstresponder }  value={'firstresponder'} onChange={chagecolumn} label="First Responder" />
<Checkbox checked={formData.theft }  value={'theft'} onChange={chagecolumn} label="Theft" />
<Checkbox checked={formData.totalmmsandsms }  value={'totalmmsandsms'} onChange={chagecolumn} label="Total SMS/MMS Credits Sent" />

 </div>
<p style={{background:'lavender',padding:'4px',color:'darkslategray'}}>Note: Enter Below fields if you want to  use yardsmart api feed for this group.</p>
    <div style={{padding:'10px',background:'#d9f1ff',borderRadius:'5px'}}>
          <input
              type="text"
              name="key"
              placeholder="Api Key"
              value={formData.key || ""}
              onChange={handleChange}
              // required
            />
            <input
              type="text"
              name="secret"
              placeholder="Api Secret"
              value={formData.secret || ""}
              onChange={handleChange}
              // required
            />
      

    </div>
<br/>

              <div className="modal-actions">
                <Button type="submit" intent="success">
                  {isEditing ? "Update" : "Add"}
                </Button>
                <Button type="button"  intent="danger" onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};




export default BusinessGroups;