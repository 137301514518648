import { doc, setDoc } from "firebase/firestore";
import { firebase } from "./firebase";
import { useAuthUser } from "react-auth-kit";

export const substractRead=(num:number)=>{
    const user:any = localStorage.getItem('_auth_state');
    const exnum =500000;
    const day = new Date().getDate().toString();
   const dayExist = localStorage.getItem(day);
   if(dayExist==null){    
    const number = (exnum-num).toString()
    localStorage.setItem(day,number)
    }else{

    const num2=parseInt(dayExist,10)
    const number = (num2-num).toString()
   //  console.log(number,'check');    
    if(parseInt(number,10)<=0){
 
       try {
        if(user!==null && JSON.parse(user).accountData!==undefined){
        setDoc(doc(firebase, "ReadExceededUsers",JSON.parse(user).accountData.email+'_'+day), {day,timestamp:new Date()})
        console.log('ReadExceededUsers');
        localStorage.setItem(day,exnum.toString())
        }
       } catch (error) {
        console.log(error);
        
       }
    }else{
    localStorage.setItem(day,number)
    }

   }
}