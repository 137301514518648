import cloneDeep from "lodash/cloneDeep";
import * as React from "react";

import { Button, Classes, ContextMenu, Dialog, Divider, H2, H3, H4, H5, Icon,InputGroup,Tooltip, Tree, TreeNodeInfo } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SiteForm } from "../components/SiteForm";
import { BusinessForm } from "../components/BusinessForm";
import { Label, Underline } from "@blueprintjs/icons";
import { InputMask } from "@react-input/mask";
import { CopyLink } from "../components/Copylink";
import { elementIsTextInput } from "@blueprintjs/core/lib/esm/common/utils";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { firebase, urls } from "../global/firebase";

type NodePath = number[];

type TreeAction =
    | { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" ; payload: any}
    | { type: "SET_IS_SELECTED"; payload: { path: NodePath; isSelected: boolean } }
    | { type: "ADD"; payload: any };

function forEachNode(nodes: TreeNodeInfo[] | undefined, callback: (node: TreeNodeInfo) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        callback(node);
        forEachNode(node.childNodes, callback);
    }
}

function forNodeAtPath(nodes: TreeNodeInfo[], path: NodePath, callback: (node: TreeNodeInfo) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

function treeExampleReducer(state: TreeNodeInfo[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL":
            const newState1 = cloneDeep(state);
            forEachNode(newState1, node => (node.isSelected = false));
                    if(action.payload!==undefined){   
            forEachNode(newState1, node => (node.isExpanded = false));
                    }
            return newState1;
        case "SET_IS_EXPANDED":
            const newState2 = cloneDeep(state);
            forNodeAtPath(newState2, action.payload.path, node => (node.isExpanded = action.payload.isExpanded));
            return newState2;
            case "SET_IS_SELECTED":
                const newState3 = cloneDeep(state);
                forNodeAtPath(newState3, action.payload.path, node => (node.isSelected = action.payload.isSelected));
                return newState3;
            case "ADD":
                const newState5 = action.payload;
                return newState5;    
        default:
            return state;
    }
}

export const Businesses: React.FC = props => {
    const [Businesses,setBusinesses]=useState([])
    const [ArchivedBusinesses,setArchivedBusinesses]=useState([])
    const [nodes,setNodes]=useState([] as any)
    const [selecteBusinessSite,setSelecteBusinessSite]=useState([] as any)
    const [nodesx, dispatch] = React.useReducer(treeExampleReducer, nodes);
    const [addnewbusiness,setaddnewbusiness]=useState(false);
    const [newBusiness,setnewBusiness]=useState({businessName:'',guid:'',shortcode:'',justInKeyword:'',yardName:'',keyword:'',path:''})
    const store = useSelector((state:any) => state)

    const [adding,setadding]=useState(false)
    const [businessName,setbusinessName]=useState('')
    const [businessNameTouched,setbusinessNameTouched]=useState(false)
    const [businessNameValid,setbusinessNameValid]=useState(false)
    const [guid,setguid]=useState('')
    const [guidTouched,setguidTouched]=useState(false)
    const [guidValid,setguidValid]=useState(false)
    const [keyword,setkeyword]=useState('')
    const [keywordTouched,setkeywordTouched]=useState(false)
    const [keywordValid,setkeywordValid]=useState(false)
    const [path,setpath]=useState('')
    const [shortcode,setshortcode]=useState('')
    const [shortcodeTouched,setshortcodeTouched]=useState(false)
    const [shortcodeValid,setshortcodeValid]=useState(false)
    const [bool,setBool]=useState(false);
    const [businessNameIntent,setbusinessNameIntent] =useState('' as any);
    const [guidIntent,setguidIntent] = useState('' as any);
    const [keywordIntent,setkeywordIntent] = useState('' as any);
    const [shortcodeIntent,setshortcodeIntent] = useState('' as any);
    const [addButtonDisabled,setaddButtonDisabled] =useState(false);
    const arrayOfExcludeBusiness = ['mUnDkSEu6vcPCjWZhY62','Ln5DhJ1DKQZYIU3IJ0R8','6lOGe0ZySPqTzgqBHwBj','O7JlStvWUvYL8ByFknAS','VbOM6wfihq5r0K2rff6K','8Ls8N48434Dx1yVuX6vh','MNPKA5eMaEEieR683Ch9'];

    useEffect(()=>{ 
                  
        setBusinesses(store.BusinessesReducer.Businesses)
        setArchivedBusinesses(store.BusinessesReducer.Archived)
        
        const allbusinesses: TreeNodeInfo[] = [];      
        const countx = (store.BusinessesReducer.Businesses.length+store.BusinessesReducer.Archived.length)
        if(store.BusinessesReducer.Businesses[0]!==undefined && store.BusinessesReducer.Businesses!==null &&  countx=== store.BusinessesReducer.len){    
            const pointarr:any=[];
            try {
                if(store.BusinessesReducer.PointBusinesses!==undefined && store.BusinessesReducer.PointBusinesses.length>0){
                    store.BusinessesReducer.PointBusinesses.map((po:any,index:any)=>{                        
                          pointarr.push(po.texn_business_uid)
                    });
                }
            } catch (error) {
                console.log(error);
                
            }
              
        store.BusinessesReducer.Businesses.map((bus:any,indexb:any)=>{
            const site:any=[];
            let Ispointsystem = false
                if(pointarr.includes(bus.businessId)){
                    Ispointsystem=true;
                }
                

            if(bus.sites!==undefined){
               bus.sites.map((sit:any,index:any)=>{
                   let archiv = ''
                
                   if(sit.isArchive!==undefined && (sit.isArchive===1 || sit.isArchive==='1')){
                     archiv = ' (Archived)'
                   }
                    site.push( {
                       id: sit.siteId,                       
                       icon: "desktop",
                       label: sit.path+archiv,
                       businessId:sit.businessId,
                       secondaryLabel: (
                           <Tooltip key={index} content={ sit.siteName}>
                               <Icon icon="eye-open" />
                           </Tooltip>
                       ),
                   },) 
               })
           }
        // if( bus.sites.length>0){
if(!arrayOfExcludeBusiness.includes(bus.businessId)){
           allbusinesses.push({
               id: bus.businessId,
               icon: "office",
               isExpanded: indexb===0 ? true :false,
               label: (
                   <ContextMenu  key={indexb} {...contentSizing} content={<div >{bus.businessName} </div>}>
                       <Tooltip  content= {bus.businessName} placement="right">
                           <div style={{display:'flex'}}>{bus.businessName}&nbsp;&nbsp;{Ispointsystem ? <Icon intent="success" icon='dollar'/>:''}</div>
                       </Tooltip>
                   </ContextMenu>
               ),
               childNodes: site,
           })
    }
        // }
   });


// #Archived
   store.BusinessesReducer.Archived.map((bus:any,indexb:any)=>{
    const site:any=[];
    if(bus.sites!==undefined){
       bus.sites.map((sit:any,index:any)=>{
           let archiv = ''
           
           if(sit.isArchive!==undefined && (sit.isArchive===1 || sit.isArchive==='1')){
                archiv = ' (Archived)'
           }
           site.push( {
               id: sit.siteId,
               icon: "desktop",
               label: sit.path+archiv,
               businessId:sit.businessId,
               secondaryLabel: (
                   <Tooltip key={index} content={ sit.siteName}>
                       <Icon icon="eye-open" />
                   </Tooltip>
               ),
           },)
       })
   }
   allbusinesses.push({
       id: bus.businessId,
       icon: 'archive',       
       isExpanded: false,
       label: (
           <ContextMenu  key={indexb} {...contentSizing} content={<div >{bus.businessName}</div>}>
               <Tooltip  content= {bus.businessName} placement="right" >
                   {bus.businessName}
               </Tooltip>
           </ContextMenu>
       ),
       childNodes: site,
   })
});

    if(allbusinesses[0]!==undefined){
    setSelecteBusinessSite({type:'business',payload:allbusinesses[0]})
    }
    const finalBusienss = allbusinesses.filter((x:any,i:any)=>{
            x.index=[i];
            const finalChildNode= x.childNodes.filter((xt:any,it:any)=>{
                xt.index=[i,it];
                return xt
            })
            x.childNodes = finalChildNode;
            return x 
    })
    setNodes(finalBusienss)
     dispatch({type:'ADD',payload:finalBusienss}) 
}else{
    setBool(!bool)   
} 
    },[bool,store.BusinessesReducer.Businesses])



    const handleNodeClick = React.useCallback(
        (node: any, nodePath: NodePath, e: React.MouseEvent<HTMLElement>) => {
            const originallySelected = node.isSelected;
            
            nodePath = node.index;
            setSelecteBusinessSite({type:node.childNodes!==undefined?'business':'site',payload:node})
            
            if (!e.shiftKey) {
                dispatch({ type: "DESELECT_ALL",payload:node.childNodes });
            }  
            
            dispatch({
                payload: { path: nodePath, isExpanded: originallySelected == null ? true : !originallySelected },
                type: "SET_IS_EXPANDED",
            });  
            dispatch({
                payload: { path: nodePath, isSelected: originallySelected == null ? true : !originallySelected },
                type: "SET_IS_SELECTED",
            });
           
        },
        [], );


        

        const handleNodeCollapse = React.useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
            dispatch({
                payload: { path: nodePath, isExpanded: false },
                type: "SET_IS_EXPANDED",
            });
        }, []);

        // const handleNodeExpand = React.useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
        //     dispatch({
        //         payload: { path: nodePath, isExpanded: true },
        //         type: "SET_IS_EXPANDED",
        //     });
        // }, []);
        const OpenAddNewBusinessPopup=()=>{
            setaddnewbusiness(true)
            
        }

        const getIntent = (touched: boolean, valid: boolean) => valid ? 'success' : touched ? 'danger' : 'none';

        const handleInput = ({
            currentTarget: { name, value },
          }: React.FormEvent<HTMLInputElement>) => {
            if (name === 'businessName') {            
              handleBusinessName(value);              
            }
            if (name === 'guid') {              
              handleGuid(value);
            }
            if (name === 'keyword') {             
              handleKeyword(value);
            }
            if (name === 'shortcode') {             
              handleShortcode(value);
            }
            
          };
        
            const convertToPath = (name: string) =>
            name
            .replace("&", "and")
            .replace(/[^\w-\s]/g, "")
            .replace(/\s/g, "-")
            .replace(/-{2,}/g, "-")
            .toLowerCase();


                const handleBusinessName = (businessName: string) =>{
                console.log(Businesses);
                const valid= isValidBusinessName(businessName, path)
                setbusinessName(businessName)
                setpath(convertToPath(businessName)); 
                setbusinessNameValid(valid)
                setbusinessNameIntent(getIntent( businessNameTouched, valid))
                }    
           

          const handleGuid = (guid: string) => {
            setguid(guid)
            const valid =isValidGuid(guid)
            setguidValid(valid)            
            setguidIntent(getIntent(guidTouched, valid))
          };
        
          const handleKeyword = (keyword: string) =>{
            setkeyword(keyword)    
            const valid =isValidKeyword(keyword)       
            setkeywordValid(valid)
            setkeywordIntent(getIntent(keywordTouched, valid))
          }

        
            const handleShortcode = (shortcode: string) =>{

              const clean = shortcode.replace(/\D/g, '');
              const shortened = clean.substring(0, 11);
              console.log(shortened);
              const valid=  isValidShortcode(shortened)
              setshortcode(shortened)                            
              setshortcodeValid(valid)
              setshortcodeIntent(getIntent(shortcodeTouched, valid))
            } 
             


        const isValidBusinessName = (newBusinessName: string, newPath: string) =>
        newBusinessName.length > 0 &&
        Businesses.every(
          ({ businessName, path }) =>
            newBusinessName !== businessName && newPath !== path,
        )
          ? true
          : false;
    
      const isValidGuid = (guid: string) =>
        guid.match(/{\w{8}-\w{4}-\w{4}-\w{4}-\w{12}}/g) ? true : false;
    
      const isValidKeyword = (keyword: string) =>
        (keyword.match(/^\w+$/g) ? true : false) || keyword === '';
    
      const isValidShortcode = (shortcode: string) => {
        const isValidShortcode1 = shortcode.match(/^\d{5}$/g) ? true : false;
        const isValidShortcode2 = shortcode.match(/^\d{11}$/g) ? true : false;
        if(isValidShortcode1 || isValidShortcode2){
          return true;
        }else{
          return false;
        }
      }



      const setTouched = ({
        currentTarget: { name },
      }: React.FocusEvent<HTMLInputElement>) => {
        if (name === 'businessName') { 
            setbusinessNameTouched(true) 
            setbusinessNameValid(isValidBusinessName(businessName, path))
        }
        if (name === 'guid') {
            setguidTouched(true)       
            setguidValid(isValidGuid(guid))
        }

        if (name === 'keyword') {
            setkeywordTouched(true)     
            setkeywordValid(isValidKeyword(keyword))
        }
        if (name === 'shortcode') {
            setshortcodeTouched(true)      
           setshortcodeValid(isValidShortcode(shortcode))
           
        }
        console.log(businessNameValid , guidValid , keywordValid ,shortcodeValid);
if(businessNameValid && guidValid &&  shortcodeValid){
        setaddButtonDisabled(false)
}else{
    setaddButtonDisabled(true)
}
      };


      const AddBusineess=async()=>{
        setadding(true)
      if(businessNameValid || guidValid ||  shortcodeValid){
        const theme:any=[];
        theme.logo ='https://just-in.texnrewards.com/favicon.ico'               
        theme.background ='linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8))'  
        theme.colors ={
            error:"#e23229",
            primary:"#2b3c8b",
            secondary:"#fcad14",
            success:"#467f1e",
            warning:"#e8a530"
           } 
           await addDoc(collection(firebase, "businesses"), {
            businessName,
            guid,
            keyword,
            path,
            shortcode,
            langEnglish:true,
            langSpanish:true,
            yardName:businessName,
            theme
          }).then(async(i:any)=>{
            console.log("Document written with ID:"+i.id);
            await updateDoc(doc(firebase, "businesses", i.id), {businessId:i.id})
                .then(docRef => {
                    console.log("A New Document Field has been added to an existing document businessId");
                    window.location.href= '';
                    setadding(false)
                })
                
            // fs9BusinessNameToUidLookup
            try {
                await setDoc(doc(firebase, "fs9BusinessNameToUidLookup", path), {businessName,businessId:i.id}).catch((e:any)=>{
                        console.log(e);                    
                });
            } catch (error) {
                console.log(error,'add business 406');
            }

          }).catch(()=>{
            alert('Invalid fields value')
            setadding(false)
          });
      
 
      }else{
        alert('Invalid fields value')
      }
      setadding(false)
      }

      const getArchiveData=(data:any)=>{
        const re =  data.filter((da:any)=>da.icon==='archive')
        return re
     }
     const getActiveData=(data:any)=>{
        const re =  data.filter((da:any)=>da.icon!=='archive')
        return re
     }
    

    return (
        <div className="container-fluid mt-10 " style={{textAlign:'left',background:'#f2f1f1'}}>
            <div className="col-md-3 bg-white p-0 mt-10">
                <Button style={{margin:'10px auto',width:'95%'}} intent="success" fill={true} onClick={()=>OpenAddNewBusinessPopup()}><Icon icon={'plus'} />&nbsp;&nbsp;&nbsp;add new business</Button>
                <Divider />
                <Tree            
                contents={getActiveData(nodesx)}
                onNodeClick={handleNodeClick}
                onNodeCollapse={handleNodeCollapse}
                // onNodeExpand={handleNodeExpand}                
                className={Classes.ELEVATION_4+' bg-white'}
            />
           <H4>&nbsp;<b> Archive businesses</b></H4>
           <Divider/>
            <Tree            
                contents={getArchiveData(nodesx)}
                onNodeClick={handleNodeClick}
                onNodeCollapse={handleNodeCollapse}
                // onNodeExpand={handleNodeExpand}                
                className={Classes.ELEVATION_4+' bg-white'}
            />
            </div>
            <div className="col-md-1"></div>
            {
                selecteBusinessSite.type!==undefined ? 
                <div className="col-md-7 p-0 mt-10">
                        {selecteBusinessSite.type==='site' ? <SiteForm  payload={selecteBusinessSite.payload}/> : selecteBusinessSite.type==='business' ? <BusinessForm  payload={selecteBusinessSite.payload}/> : '' }
                </div> 
                : ''
            }
            <div className="col-md-1"></div>


            <Dialog
                title="Add New Business"
                icon="info-sign"
                isOpen={addnewbusiness}
                onClose={()=>setaddnewbusiness(false)}
            >
                <div className={Classes.DIALOG_BODY}>
                <div className="form-field">
                    <H5>Business Name </H5>
                    <InputGroup
                       intent={businessNameIntent}
                       type="text"
                        disabled={false}
                        large={false}
                        placeholder="Enter Business Name"
                        readOnly={false}                    
                        value={businessName}
                        name="businessName"
                        onBlur={setTouched}
                        onChange={handleInput}
                    />
                </div>
                <div className="form-field">
                <H5>Ivision guid: </H5>  
                <div className="bp5-input-group">
                    <InputMask     onBlur={setTouched} color={guidIntent} name="guid"  onChange={handleInput} className="bp5-input font-20" mask="______________________________________" replacement={"_"} showMask separate  disabled={false} value={guid}/>   
                </div>                     
        </div>
                <div className="form-field">
                    <H5>Ivision Keyword ( Optional ) </H5>
                    <InputGroup
                    intent={keywordIntent}
                    type="text"
                        disabled={false}
                        name="keyword"
                        large={false}
                        placeholder="Enter Ivision Keyword "
                        readOnly={false}                    
                        value={keyword}
                        onChange={handleInput}
                        onBlur={setTouched}
                    />
                </div>
                <div className="form-field">
                    <H5>Ivision Code </H5>
                    <InputGroup
                    intent={shortcodeIntent}
                    type="text"
                        disabled={false}
                        large={false}
                        name="shortcode"
                        placeholder="Enter Ivision Code"
                        readOnly={false}                    
                        value={shortcode}
                        onChange={handleInput}
                        onBlur={setTouched}
                    />
                </div>
            <div className="form-field">
               <H5>Just in landing page link</H5>
               <CopyLink url={urls.baseUrlJustIn+'/'+path+'/-/'} />
                </div>
                <div className="form-field">
                <Button loading={adding} intent="success" onClick={()=>AddBusineess()} disabled={addButtonDisabled}>Add Busienss</Button>                    
                </div>
                
                </div>
            </Dialog>
            </div>
    
    );
};

const contentSizing = { popoverProps: { popoverClassName: Classes.POPOVER_CONTENT_SIZING } };

